import React from 'react';
import './Answer.css';

import DialogLink from '../../Components/DialogLink'
import womenInManagementFullSize from '../../assets/Employment_in_senior_and_middle_management.png';

const B1 = () => {
  return (
    <div className="answer">
      <a href='https://www.cbs.gov.il/he/mediarelease/DocLib/2024/069/11_24_069b.pdf#page=18'
         className="link"
         target='_blank' rel="noopener noreferrer">מתוך לקט נתונים</a>
      &nbsp;של הלשכה המרכזית לסטטיסטיקה עולה כי בשנת 2021 ההכנסה (ברוטו) של אישה עצמאית הייתה 53.9% מההכנסה (ברוטו) של גבר עצמאי, וההכנסה (ברוטו) של אישה שכירה הייתה 66.1% ההכנסה (ברוטו) של גבר שכיר.
      <br />
      כמו כן, השכר הממוצע החודשי של נשים בתפקידים בכירים של ניהול כללי בשנת 2020 עמד על 39,651 ש"ח, 88% משכרו של גבר בתפקיד בכיר (44,710 ש"ח). פער השכר הקטן ביותר בין מנכ"לים למנכ"ליות נמצא בגופים ציבוריים במערכת הבריאות (כ-3,000 ש"ח בחודש), ואילו פער השכר הגדול ביותר נמצא בחברות ממשלתיות (יותר כ-12,000 ש"ח בחודש).
      <br />
      <br />
      ומה מצב ישראל מול העולם?
      <ul>
        <li>
          <DialogLink linkText="ישראל במקום השני מהסוף, לאחר קוריאה, בדירוג פערי השכר בין נשים לגברים בין מדינות ה-OECD"
                      graphLink="https://data.oecd.org/chart/7aNj"
                      graphTitle="Gender_wage_gap_2022"
                      graphLinkCaption="OECD Chart: Gender wage gap, Employees, Percentage, Annual, 2022 or latest available" />
        </li>
        <li>
          <DialogLink linkText="לפי ה-OECD הפערים בעשור האחרון בישראל, החל מ2012, צמחו מ-20% ללמעלה מ-25%"
                      graphLink="https://data.oecd.org/chart/7clR"
                      graphTitle="Gender_wage_gap_2012_2022"
                      graphLinkCaption="OECD Chart: Gender wage gap, Employees, Percentage, Annual, 2012 – latest" />
        </li>
        <li>
          <DialogLink linkText="לפי נתוני הבנק העולמי, ייצוג הנשים בעמדות הנהלה בישראל עומד על כ-33%"
                      graphLink="https://genderdata.worldbank.org/en/indicator/sl-emp-smgt-fe-zs?view=bar&geos=ISR_USA_ESP_FRA_DEU_GBR"
                      graphTitle="Gender_wage_gap_2022"
                      graphLinkCaption="Employment in senior and middle management, female (%)"
                      imageSrc={womenInManagementFullSize}
                      />
        </li>
        <li>
          <DialogLink linkText="ייצוג הנשים בממשלה בישראל עומד על כ-12.5%, לעומת ייצוג ממוצע של כ-37% במדינות ה-OECD"
                      graphLink="https://data.oecd.org/chart/7clU"
                      graphTitle="Gender_politics_2022"
                      graphLinkCaption="OECD Chart: Women in politics, Women ministers, Percentage, Annual, 2022 or latest available" />
        </li>
      </ul>
    </div>
  )
}

export default B1;
