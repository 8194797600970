import React from 'react';

import ReactGA from 'react-ga4';

import { ChosenReportProvider } from './Context/ChosenReportContext';
import { DisplayedReportPdfProvider } from './Context/DisplayedReportPdfContext';
import { ReportListServerlessProvider } from './Context/ReportListServerlessContext';
import AppTabs from './AppTabs';

const App = () => {
  ReactGA.initialize('G-PEHYBCG7HE');

  return (
    <ChosenReportProvider>
    <DisplayedReportPdfProvider>
    <ReportListServerlessProvider>
      <AppTabs />
    </ReportListServerlessProvider>
    </DisplayedReportPdfProvider>
    </ChosenReportProvider>
  );
};

export default App;
