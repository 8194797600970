export const R3 = {
    "category":"ממשלתי",
    "reports":[
        {
            "name":"משרד מבקר המדינה ונציב תלונות הציבור",
            "engName":"Mevaker",
            "year":2021,
            "reportUrl":"https://www.mevaker.gov.il/he/Transparency/DocLib1/EqualSalary-2021.pdf",
            "originUrl":"https://www.mevaker.gov.il/he/Transparency/Pages/Salary.aspx?AspxAutoDetectCookieSupport=1",
            "reportPath":"משרד_מבקר_המדינה_ונציב_תלונות_הציבור_2021.pdf",
            "missing":false,
            "note":""
        },
        {
            "name":"משרד מבקר המדינה ונציב תלונות הציבור",
            "engName":"Mevaker",
            "year":2022,
            "reportUrl":"https://www.mevaker.gov.il/he/Transparency/DocLib1/EqualSalary-2022.pdf",
            "originUrl":"https://www.mevaker.gov.il/he/Transparency/Pages/Salary.aspx?AspxAutoDetectCookieSupport=1",
            "reportPath":"משרד_מבקר_המדינה_ונציב_תלונות_הציבור_2022.pdf",
            "missing":false,
            "note":""
        },
        {
            "name":"עמידר החדשה",
            "engName":"Amidar",
            "year":2022,
            "reportUrl":"https://www.amidar.co.il/wps/wcm/connect/e6c504ce-3f6e-429b-80a9-f5c1eddd0376/%D7%93%D7%95%D7%97_%D7%A9%D7%9B%D7%A8_%D7%A9%D7%95%D7%95%D7%94_2022_-_%D7%A4%D7%95%D7%A8%D7%9E%D7%98_%D7%A4%D7%95%D7%9E%D7%91%D7%99.pdf?MOD=AJPERES&CVID=",
            "originUrl":"https://www.amidar.co.il/wps/portal/amidar/main-menu/about/equal-re",
            "reportPath":"עמידר_החדשה_2022.pdf",
            "missing":false,
            "note":""
        }
    ]
};
