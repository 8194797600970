import React from 'react';
import './Answer.css';

import { navigate } from '../Utils'

const B2 = () => {
  return (
    <div className="answer">
      <p>
        באתר זה ניתן למצוא כ180 דוחות של חברות שונות בשנים 2021-2022.
        מאחר וכל חברה מוציאה דוחות בפורמטים שונים, ואין שורה תחתונה אחידה המשותפת לכל הדוחות, כרגע האתר רק מפרסם את הדוחות כמו שהם. בתקווה בעתיד נוכל לנתח את התוצאות בצורה מעמיקה יותר, ולשלוף מהן נתונים שימושיים.
        <br />
        לצפייה בדוחות לחצו&nbsp;
        <a href="#reports"
           className="link"
           onClick={() => navigate("reports")}>
           כאן
        </a>.
      </p>
    </div>
  )
}

export default B2;
