export const R4 = {
    "category":"גז, נפט ואנרגיה",
    "reports":[
        {
            "name":"אנלייט אנרגיה",
            "engName":"enlightenergy",
            "year":2022,
            "reportUrl":"https://enlightenergy.co.il/wp-content/uploads/2023/06/Equality-Report.pdf",
            "originUrl":"",
            "reportPath":"אנלייט_אנרגיה_2022.pdf",
            "missing":false,
            "note":"",
			"mainFindings":"עובדי החברה פולחו ל-7 קבוצות בעלות מאפיינים דומים של תפקידים, ונמצא כי הפער קטן ונע בין ,3.5%-1.1% כאשר בחלק מהקבוצות הפער לטובת נשים ובחלקן לטובת הגברים."
        },
        {
            "name":"קבוצת דלק",
            "engName":"Delek",
            "year":2021,
            "reportUrl":"https://ir.delek-group.com/wp-content/uploads/2023/05/%D7%97%D7%95%D7%A7-%D7%A9%D7%9B%D7%A8-%D7%A9%D7%95%D7%95%D7%94-%D7%93%D7%95%D7%97-%D7%A4%D7%95%D7%9E%D7%91%D7%99-%D7%99%D7%95%D7%A0%D7%99-2022.pdf",
            "originUrl":"",
            "reportPath":"קבוצת_דלק_2021.pdf",
            "missing":false,
            "note":""
        },
        {
            "name":"פז",
            "engName":"Paz",
            "year":2021,
            "reportUrl":"https://paz.co.il/Uploads//%D7%9E%D7%95%D7%A2%D7%A8%D7%91%D7%95%D7%AA%20%D7%97%D7%91%D7%A8%D7%AA%D7%99%D7%AA/%D7%97%D7%95%D7%A7%20%D7%A9%D7%9B%D7%A8%20%D7%A9%D7%95%D7%95%D7%94%20%D7%9C%D7%A2%D7%95%D7%91%D7%93%D7%AA%20%D7%95%D7%9C%D7%A2%D7%95%D7%91%D7%93.pdf",
            "originUrl":"",
            "reportPath":"פז_2021.pdf",
            "missing":false,
            "note":""
        },
        {
            "name":"פז",
            "engName":"Paz",
            "year":2022,
            "reportUrl":"https://www.paz.co.il/assets/%D7%97%D7%95%D7%A7%20%D7%A9%D7%9B%D7%A8%20%D7%A9%D7%95%D7%95%D7%94%20%D7%9C%D7%A2%D7%95%D7%91%D7%93%D7%AA%20%D7%95%D7%9C%D7%A2%D7%95%D7%91%D7%93%20-%20%D7%A4%D7%A8%D7%A1%D7%95%D7%9D%20%D7%91%D7%90%D7%AA%D7%A8%20%D7%A4%D7%96.pdf",
            "originUrl":"",
            "reportPath":"",
            "missing":true,
            "note":"broken"
        },
        {
            "name":"דור אלון",
            "engName":"doralon",
            "year":2021,
            "reportUrl":"https://www.doralon.co.il/%D7%97%D7%95%D7%A7-%D7%A9%D7%9B%D7%A8-%D7%A9%D7%95%D7%95%D7%94/",
            "originUrl":"",
            "reportPath":"",
            "missing":true,
            "note":"missing - only 2022 exists"
        },
        {
            "name":"דור אלון",
            "engName":"doralon",
            "year":2022,
            "reportUrl":"https://www.doralon.co.il/%D7%97%D7%95%D7%A7-%D7%A9%D7%9B%D7%A8-%D7%A9%D7%95%D7%95%D7%94/",
            "originUrl":"",
            "reportPath":"",
            "missing":false,
            "note":"html"
        },
        {
            "name":"טן",
            "engName":"Ten",
            "year":2022,
            "reportUrl":"https://10ten.co.il/%D7%97%D7%95%D7%A7_%D7%A9%D7%9B%D7%A8_%D7%A9%D7%95%D7%95%D7%94",
            "originUrl":"",
            "reportPath":"",
            "missing":false,
            "note":"html"
        },
        {
            "name":"ניומד אנרג'י",
            "engName":"newmedenergy",
            "year":2022,
            "reportUrl":"https://newmedenergy.com/wp-content/uploads/2023/03/equal-remuneration-report.pdf",
            "originUrl":"",
            "reportPath":"ניומד_אנרגי_2022.pdf",
            "missing":false,
            "note":""
        },
        {
            "name":"OPC אנרגיה",
            "engName":"OPC",
            "year":2021,
            "reportUrl":"https://opc-energy.com/wp-content/uploads/2022/06/%D7%97%D7%95%D7%A7-%D7%A9%D7%9B%D7%A8-%D7%A9%D7%95%D7%95%D7%94-%D7%93%D7%95%D7%97-%D7%A4%D7%95%D7%9E%D7%91%D7%99-%D7%9C%D7%A9%D7%A0%D7%AA-2021.pdf",
            "originUrl":"",
            "reportPath":"OPC_אנרגיה_2021.pdf",
            "missing":false,
            "note":""
        },
        {
            "name":"OPC אנרגיה",
            "engName":"OPC",
            "year":2022,
            "reportUrl":"https://www.opc-energy.com/wp-content/uploads/2023/06/%D7%93%D7%95%D7%97-%D7%A4%D7%95%D7%9E%D7%91%D7%99-%D7%97%D7%95%D7%A7-%D7%A9%D7%9B%D7%A8-%D7%A9%D7%95%D7%95%D7%94-30.5.23-002.pdf",
            "originUrl":"",
            "reportPath":"",
            "missing":true,
            "note":"missing - left only 2021"
        },
        {
            "name":"אפקון",
            "engName":"afcon",
            "year":2021,
            "reportUrl":"https://www.afcon.co.il/app/uploads/%D7%93%D7%95%D7%97-%D7%A9%D7%9B%D7%A8-%D7%A9%D7%95%D7%95%D7%94.pdf",
            "originUrl":"",
            "reportPath":"אפקון_2021.pdf",
            "missing":false,
            "note":""
        },
        {
            "name":"משק אנרגיה",
            "engName":"mske",
            "year":2021,
            "reportUrl":"https://mske.co.il/wp-content/uploads/2022/05/%D7%A4%D7%A2%D7%A8%D7%99-%D7%A9%D7%9B%D7%A8-%D7%93%D7%99%D7%95%D7%95%D7%97-31-%D7%91%D7%9E%D7%90%D7%99-2022.pdf",
            "originUrl":"",
            "reportPath":"משק_אנרגיה_2021.pdf",
            "missing":false,
            "note":""
        },
        {
            "name":"משק אנרגיה",
            "engName":"mske",
            "year":2022,
            "reportUrl":"https://mske.co.il/wp-content/uploads/2023/07/%D7%93%D7%95%D7%97-%D7%A4%D7%A2%D7%A8%D7%99-%D7%A9%D7%9B%D7%A8-%D7%99%D7%95%D7%A0%D7%99-2023.pdf",
            "originUrl":"",
            "reportPath":"משק_אנרגיה_2022.pdf",
            "missing":false,
            "note":""
        },
        {
            "name":"נופר אנרגיה",
            "engName":"nofar-energy",
            "year":2021,
            "reportUrl":"https://www.nofar-energy.com/warehouse/dynamic/330974.pdf",
            "originUrl":"https://www.nofar-energy.com/about/reports",
            "reportPath":"נופר_אנרגיה_2021.pdf",
            "missing":false,
            "note":"",
			"mainFindings":"בדוח ניתן לראות כי הפער המשמעותי ביותר בחברה, בהתבסס על חלוקה למחלקות מקצועיות, הינו פער של 50% לרעת המגדר הגברי. עם זאת, ישנם פערים לרעת המגדר הנשי בהיקף של 4%-27%, בהתאם למחלקה."
        },
        {
            "name":"נאוויטס פטרוליום",
            "engName":"navitaspet",
            "year":2022,
            "reportUrl":"https://ir.navitaspet.com/%D7%97%D7%95%D7%A7-%D7%A9%D7%9B%D7%A8-%D7%A9%D7%95%D7%95%D7%94/",
            "originUrl":"",
            "reportPath":"",
            "missing":true,
            "note":"broken"
        }
    ]
};
