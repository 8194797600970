import React from 'react';
import './Contact.css';

const Contact = () => {
  return (
    <div>
      <p className="contact-content">
        אני אורית מוסקוביץ (
        <a href="https://www.linkedin.com/in/oritmosko/" target="_blank">לינקדאין</a>),
        בניתי את האתר מתוך רצון להציף ולהנגיש את המידע שקיים, בתקווה שזה יעזור בעתיד להזיז את המחט.
      </p>
      <p className="contact-content">
        יש לך שאלה? הצעה לשיפור? מצאת דו"ח שלא פורסם באתר? ואם סתם בא לך להגיב - אנא כתבו בטופס או למייל&nbsp;
        <a href="mailto:pearim.il2024@gmail.com" target="_blank">pearim.il2024@gmail.com</a>
      </p>
      <iframe
        src="https://docs.google.com/forms/d/1Uo0TdrhR-HAqJgzIZbeqeMGV-QODjT7Q34b2HpNxFNU/viewform?embedded=true"
        title="missingReport"
        className="google-form"
        frameBorder="0">
        Loading...
      </iframe>
    </div>
  );
};

export default Contact;
