import React from 'react';
import './About.css';

import B0 from './Answers/B0'
import B1 from './Answers/B1'
import B2 from './Answers/B2'
import B3 from './Answers/B3'

const About = () => {
  const qAndAData = [
    {
      question: 'אמ;לק',
      answer: <B0 />
    },
    {
      question: 'למה זה חשוב?',
      answer: <B1 />
    },
    {
      question: 'מה יש באתר?',
      answer: <B2 />
    },
    {
      question: 'איך אפשר לעזור?',
      answer: <B3 />
    },
  ];

  return (
    <div className="about-content">
      {qAndAData.map((item, index) => (
        <div key={index} className="qna-item">
          <h3 className="question">{item.question}</h3>
          {item.answer}
        </div>
      ))}
    </div>
  );
};

export default About;
