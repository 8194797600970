import React from 'react';
import './Answer.css';

import { navigate } from '../Utils'

const C4 = () => {
  return (
    <div className="answer">
      <p>
        <b>
        הגופים הציבוריים (חברות ממשלתיות, רשויות מקומיות, עיריות, אוניברסיטאות, גופים במערכת הבריאות ועוד) לא פרסמו את הדוח הפומבי לשנת 2022.
        נכון לשנת 2021, השכר הממוצע לחודש של העובדות הוא 63% מהשכר הממוצע לחודש של העובדים בכלל העובדים בגופים הציבוריים
        ולא נכללו בו 39 גופים מסיבות שונות כגון אי השלמת הדיווח.
        </b>
      </p>
      <p>
        <b>
        משרדי הממשלה, גם כן, לא פרסמו את הדוח הפומבי לשנת 2022.
        נכון לשנת 2021, נשים בתפקידים בלשכות שר ומנכ"ל מרוויחות 90% משכר הגברים.
        במטה לבטחון לאומי, נשים מרוויחות 72% משכר הגברים.
        </b>
        <br />
        נתון נוסף מעניין, נראה שהפער בין שכר נשים לשכר גברים בהסכמים קיבוציים גדול מהפער בחוזים מיוחדים בתפקידים מקבילים:
        <br />
        כאשר מסתכלים על אקדמאים בהסכמים קיבוציים, נשים מרוויחות 79% משכר הגברים הממוצע.
        <br />
        הדבר דומה גם בקרב משפטנים בהסכמים קיבוציים, שם נשים מרוויחות 93% משכר הגברים הממוצע.
        <br />
        הפערים קטנים יותר כאשר מסתכלים על הקטגוריות תחת חוזים מיוחדים.
        עוד על פערי שכר מגדריים לפי דירוג במשרדי הממשלה תוכלו למצוא&nbsp;
        <a href='https://www.gov.il/BlobFolder/reports/salary-supervisor-report-gender-wage-differentials-2021-main-findings/he/Publishes_Reports_Files_salary-supervisor-report-gender-wage-differentials-2021-presentation.pdf#page=3'
           className="link"
           target='_blank' rel="noopener noreferrer">כאן.</a>
      </p>
      <p>
        <b>
        וביתר המגזרים, השונות בדוחות היא גדולה.
        </b>
        <br />
        יש חברות עם 4 קבוצות פילוח, ויש חברות עם 60 ויותר קבוצות. אין פירוט איזה אופי עבודה כל קבוצה מייצגת, או מה המכנה המשותף לכל קבוצת פילוח. יתרה על כך, בקרב החברות עבורן נמצאו דוחות גם לשנת 2021 וגם לשנת 2022, אין בהכרח אחידות בפורמט ואף לא במספר הקבוצות בדוח, כך שאפילו למדוד התקדמות בתוך חברה זו משימה קשה במצב הנוכחי, שלא לדבר על ניתוח ברמת סקטור.
        <br />
        לצפייה בדוחות לחצו&nbsp;
        <a href="#reports"
           className="link"
           onClick={() => navigate("reports")}>
           כאן
        </a>.

      </p>
    </div>
  )
}

export default C4;
