import React from 'react';
import './Answer.css';


const C3 = () => {
  return (
    <div className="answer">
      <p>
        גם מבלי לדעת מי האוכלוסייה בכל פילוח, שכן זה מידע שחברות לא חושפות, ניתן ללמוד הרבה מהדוחות:
      </p>
      <ul>
        <li>
          יש פערי שכר משמעותיים בכמעט כל החברות ובכל הפילוחים.
        </li>
        <li>
          יש פילוחים בדוחות שנותרו ריקים מטעמי פרטיות של העובדים באותה קבוצת פילוח. מה זה אומר? ככל הנראה מדובר קבוצה שמכילה רק נשים או רק גברים. אמנם זה לא מצביע על פער, אבל מציף בעיה אחרת חשובה לא פחות - הומוגניות מגדר בתפקידים מסוימים.
        </li>
        <li>
          פערים בין שכר קובע לפיצויים (השכר המדווח לביטוח הפנסיוני) לבין שכר ברוטו (שכר המדווח למס הכנסה). מה זה אומר? שיש פערים שמקורם בפרמטרים אחרים מלבד שכר הבסיס (מענקי מניות, בונוסים מבוססי ביצועים וכו').
        </li>
      </ul>
    </div>
  )
}

export default C3;
