export const R11 = {
    "category":"ביטוח, פיננסים והשקעות",
    "reports":[
        {
            "name":"הראל",
            "engName":"Harel Group",
            "year":2021,
            "reportUrl":"https://www.harel-group.co.il/about/CSR/DocLib/%D7%97%D7%95%D7%A7%20%D7%A9%D7%9B%D7%A8%20%D7%A9%D7%95%D7%95%D7%94%20%D7%9C%D7%A2%D7%95%D7%91%D7%93%D7%AA%20%D7%95%D7%9C%D7%A2%D7%95%D7%91%D7%93%202021.pdf",
            "originUrl":"",
            "reportPath":"הראל_2021.pdf",
            "missing":false,
            "note":"",
			"mainFindings":"מממצאי הדוח, עולה כי בחלק מהקבוצות יש פערי שכר לטובת הגברים ובחלק לטובת הנשים, ובכל מקרה הפער הגדול ביותר שישנו בקבוצות לטובת גברים הינו בשיעור של 8.3% ולטובת נשים הינו בשיעור 10.9%."
        },
        {
            "name":"הראל",
            "engName":"Harel Group",
            "year":2022,
            "reportUrl":"https://www.harel-group.co.il/about/CSR/DocLib/%D7%97%D7%95%D7%A7%20%D7%A9%D7%9B%D7%A8%20%D7%A9%D7%95%D7%95%D7%94%20%D7%9C%D7%A2%D7%95%D7%91%D7%93%D7%AA%20%D7%95%D7%9C%D7%A2%D7%95%D7%91%D7%93%202022.pdf",
            "originUrl":"",
            "reportPath":"הראל_2022.pdf",
            "missing":false,
            "note":"",
			"mainFindings":"מממצאי הדוח, עולה כי בחלק מהקבוצות יש פערי שכר לטובת הגברים ובחלק לטובת הנשים, ובכל מקרה הפער הגדול ביותר שישנו בקבוצות לטובת גברים הינו בשיעור של 5.7% ולטובת נשים הינו בשיעור 7.1%."
        },
        {
            "name":"IBI בית השקעות",
            "engName":"IBI",
            "year":2021,
            "reportUrl":"https://www.ibi.co.il/wp-content/uploads/2022/05/%D7%93%D7%95%D7%97-%D7%A9%D7%9B%D7%A8-%D7%A9%D7%95%D7%95%D7%94-%D7%9C%D7%A2%D7%95%D7%91%D7%93-%D7%95%D7%9C%D7%A2%D7%95%D7%91%D7%93%D7%AA-%D7%9C%D7%90%D7%AA%D7%A8-%D7%94%D7%97%D7%91%D7%A8%D7%94.pdf",
            "originUrl":"",
            "reportPath":"IBI_בית_השקעות_2021.pdf",
            "missing":false,
            "note":""
        },
        {
            "name":"IBI בית השקעות",
            "engName":"IBI",
            "year":2022,
            "reportUrl":"https://www.ibi.co.il/wp-content/uploads/2022/05/%D7%93%D7%95%D7%97-%D7%A4%D7%95%D7%9E%D7%91%D7%99-%D7%97%D7%95%D7%A7-%D7%A9%D7%9B%D7%A8-%D7%A9%D7%95%D7%95%D7%94-%D7%9C%D7%A2%D7%95%D7%91%D7%93-%D7%95%D7%9C%D7%A2%D7%95%D7%91%D7%93%D7%AA.pdf",
            "originUrl":"",
            "reportPath":"IBI_בית_השקעות_2022.pdf",
            "missing":false,
            "note":""
        },
        {
            "name":"אי.בי.אי - ניהול וחיתום",
            "engName":"IBI",
            "year":2021,
            "reportUrl":"https://www.ibiui.co.il/wp-content/uploads/2022/06/%D7%A4%D7%95%D7%A2%D7%9C%D7%99%D7%9D-%D7%90%D7%99.%D7%91%D7%99.%D7%90%D7%99-%D7%93%D7%95%D7%97-%D7%A4%D7%95%D7%9E%D7%91%D7%99-%D7%97%D7%95%D7%A7-%D7%A9%D7%9B%D7%A8-%D7%A9%D7%95%D7%95%D7%94-%D7%9C%D7%A2%D7%95%D7%91%D7%93-%D7%95%D7%9C%D7%A2%D7%95%D7%91%D7%93%D7%AA-1.1-%D7%A0%D7%92%D7%99%D7%A9.pdf",
            "originUrl":"",
            "reportPath":"אי.בי.אי_-_ניהול_וחיתום_2021.pdf",
            "missing":false,
            "note":""
        },
        {
            "name":"אי.בי.אי - ניהול וחיתום",
            "engName":"IBI",
            "year":2022,
            "reportUrl":"https://www.ibiui.co.il/wp-content/uploads/2023/06/%D7%90%D7%99.%D7%91%D7%99.%D7%90%D7%99-%D7%A0%D7%99%D7%94%D7%95%D7%9C-%D7%95%D7%97%D7%99%D7%AA%D7%95%D7%9D-%D7%93%D7%95%D7%97-%D7%A4%D7%95%D7%9E%D7%91%D7%99-%D7%97%D7%95%D7%A7-%D7%A9%D7%9B%D7%A8-%D7%A9%D7%95%D7%95%D7%94-%D7%9C%D7%A2%D7%95%D7%91%D7%93-%D7%95%D7%9C%D7%A2%D7%95%D7%91%D7%93%D7%AA-2023-%D7%A0%D7%92%D7%99%D7%A9.pdf",
            "originUrl":"",
            "reportPath":"אי.בי.אי_-_ניהול_וחיתום_2022.pdf",
            "missing":false,
            "note":""
        },
        {
            "name":"הפניקס",
            "engName":"Fnx",
            "year":2021,
            "reportUrl":"https://www.fnx.co.il/Documents/equal-pay-law2021.pdf",
            "originUrl":"",
            "reportPath":"הפניקס_2021.pdf",
            "missing":false,
            "note":"",
			"mainFindings":"לאחר ניתוח הנתונים, נמצא כי ב-81% מקטגוריות העיסוק לא נמצאו פערי שכר בין נשים לגברים. ב-7% מקטגוריות העיסוק פער השכר הינו לטובת הנשים, ב-11% מקטגוריות העיסוק פער השכר הינו לטובת הגברים, כאשר פערי השכר המקסימליים שנמצאו הינם עד 9%."
        },
        {
            "name":"הפניקס",
            "engName":"Fnx",
            "year":2022,
            "reportUrl":"https://www.fnx.co.il/Documents/equal-pay-law2022.pdf",
            "originUrl":"",
            "reportPath":"הפניקס_2022.pdf",
            "missing":false,
            "note":"",
			"mainFindings":"מניתוח הנתונים, עולה שב- 86% מקטגוריות העיסוק לא נמצאו פערי שכר בין נשים לגברים. ב-3% מקטגוריות העיסוק פער השכר הינו לטובת הנשים, ב-11% מקטגוריות העיסוק פער השכר הינו לטובת הגברים, כאשר פערי השכר המקסימליים שנמצאו הינם עד 9%."
        },
        {
            "name":"פסגות",
            "engName":"Psagot",
            "year":2022,
            "reportUrl":"https://www.psagot.co.il/wp-content/uploads/2023/06/%D7%93%D7%95%D7%97-%D7%A9%D7%9B%D7%A8-%D7%A9%D7%95%D7%95%D7%94-2023-%D7%A4%D7%95%D7%9E%D7%91%D7%99-1.pdf",
            "originUrl":"",
            "reportPath":"פסגות_2022.pdf",
            "missing":false,
            "note":""
        },
        {
            "name":"אלטשולר שחם",
            "engName":"As Invest",
            "year":2021,
            "reportUrl":"https://www.as-invest.co.il/media/18364/%D7%93%D7%95%D7%97-%D7%A4%D7%95%D7%9E%D7%91%D7%99-2021-_-%D7%99%D7%95%D7%A0%D7%99-2022.pdf",
            "originUrl":"",
            "reportPath":"אלטשולר_שחם_2021.pdf",
            "missing":false,
            "note":""
        },
        {
            "name":"אלטשולר שחם",
            "engName":"As Invest",
            "year":2022,
            "reportUrl":"https://www.as-invest.co.il/media/21437/%D7%93%D7%95%D7%97-%D7%A4%D7%95%D7%9E%D7%91%D7%99-%D7%9C%D7%A9%D7%A0%D7%AA-2022.pdf",
            "originUrl":"",
            "reportPath":"אלטשולר_שחם_2022.pdf",
            "missing":false,
            "note":""
        },
        {
            "name":"כלל",
            "engName":"Clalbit",
            "year":2022,
            "reportUrl":"https://www.clalbit.co.il/media/26026/public-report-commissionaire-designed-version.pdf",
            "originUrl":"https://www.clalbit.co.il/diversityandinclusion/",
            "reportPath":"כלל_2022.pdf",
            "missing":false,
            "note":""
        },
        {
            "name":"מנורה מבטחים",
            "engName":"Menora",
            "year":2021,
            "reportUrl":"https://cdn.menoramivt.co.il/public/docs/20220620/%D7%97%D7%95%D7%A7-%D7%A9%D7%9B%D7%A8-%D7%A9%D7%95%D7%95%D7%94-%D7%9C%D7%A2%D7%95%D7%91%D7%93%D7%AA-%D7%95%D7%9C%D7%A2%D7%95%D7%91%D7%93-%D7%9E%D7%A0%D7%95%D7%A8%D7%94-%D7%9E%D7%91%D7%98%D7%97%D7%99%D7%9D-%D7%94%D7%97%D7%96%D7%A7%D7%95%D7%AA.pdf",
            "originUrl":"https://www.menoramivt.co.il/rep-list/equal-wage-law-holdings",
            "reportPath":"מנורה_מבטחים_2021.pdf",
            "missing":false,
            "note":""
        },
        {
            "name":"מנורה מבטחים",
            "engName":"Menora",
            "year":2022,
            "reportUrl":"https://cdn.menoramivt.co.il/public/docs/20230531/%D7%94%D7%97%D7%96%D7%A7%D7%95%D7%AA-22.5.23.pdf",
            "originUrl":"https://www.menoramivt.co.il/rep-list/equal-wage-law-holdings",
            "reportPath":"מנורה_מבטחים_2022.pdf",
            "missing":false,
            "note":""
        },
        {
            "name":"ביטוח ישיר",
            "engName":"555",
            "year":2022,
            "reportUrl":"https://www.555.co.il/resources_555/downloads/equal_pay_for_employees_2022.pdf",
            "originUrl":"https://www.555.co.il/about/career/equal_pay_for_employees.html",
            "reportPath":"ביטוח_ישיר_2022.pdf",
            "missing":false,
            "note":""
        },
        {
            "name":"מור בית השקעות",
            "engName":"More Invest",
            "year":2021,
            "reportUrl":"https://www.moreinvest.co.il/media/pcxb1h3q/2740770333_equal_pay_a4.pdf",
            "originUrl":"",
            "reportPath":"מור_בית_השקעות_2021.pdf",
            "missing":false,
            "note":""
        },
        {
            "name":"מור בית השקעות",
            "engName":"More Invest",
            "year":2022,
            "reportUrl":"https://www.moreinvest.co.il/media/3gbpd05c/res_%D7%A9%D7%9B%D7%A8-%D7%A9%D7%95%D7%95%D7%94-2022.pdf",
            "originUrl":"",
            "reportPath":"מור_בית_השקעות_2022.pdf",
            "missing":false,
            "note":""
        },
        {
            "name":"מגדל",
            "engName":"Migdal",
            "year":2021,
            "reportUrl":"https://www.migdal.co.il/media/18879/publicreport_migdal2022_a4_d_final.pdf",
            "originUrl":"",
            "reportPath":"מגדל_2021.pdf",
            "missing":false,
            "note":"",
			"mainFindings":"הדוח הפומבי כולל 21 קבוצות, כאשר ב-9 מהקבוצות נצפו פערים מינוריים שבין 0.5% ל- 2% חלקם לטובת הנשים וחלקם לטובת הגברים. ב-50% מתוך 12 הקבוצות הנותרות נוטים הפערים לטובת הנשים וב-50% לטובת הגברים. הפער באף אחת מהקבוצות אינו עולה על 4%, ובקבוצות הגדולות הוא עומד על 1.5% בלבד."
        },
        {
            "name":"מגדל",
            "engName":"Migdal",
            "year":2022,
            "reportUrl":"https://www.migdal.co.il/media/21783/publicreport_migdal2023_a4.pdf",
            "originUrl":"",
            "reportPath":"מגדל_2022.pdf",
            "missing":false,
            "note":"",
			"mainFindings":"הפער הממוצע בחברה בין שכר הנשים לגברים, בהתאם להוראות הדיווח בחוק, במרבית קבוצות ההתייחסות, נע בין 0.5% ל- 3%. כ-80% מעובדי חברת מגדל ביטוח הן נשים ובקרב הדרגים הניהוליים כ-60% הן מנהלות."
        }
    ]
};
