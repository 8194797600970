import React from 'react';
import './Answer.css';


const A3 = () => {
  return (
    <div className="answer">
      <p>
        אמנם חברות מפרסמות נתונים על פערי השכר, אך אין אחידות או הנחייה לגבי הדרך בה יעשה הפילוח, כל חברה בונה את הדוח לראות עיניה, והמידע עמוס ולא מסודר, וקשה מאוד לניתוח ועיבוד.
        אילו הייתה שקיפות ואחידות בנוגע למידע שחברות צריכות להציג, היה ניתן להסיק מסקנות לפי סקטורים, לפי פילוחים, ואפילו לעקוב על התפתחות הפער משנה לשנה בכל חברה - כל אלו חסרים כרגע.
        <br />
        ניתן למצוא את ההגדרות והוראות כלליות למילוי הדיווח&nbsp;
        <a href='https://www.gov.il/BlobFolder/generalpage/employers-publish-pay-gap/he/shivion_equal-opportunities-at-work_fix6-instructions230423.pdf'
           className="link"
           target='_blank' rel="noopener noreferrer">כאן.</a>
        &nbsp;כמה מההנחיות, כלשונן:
      </p>
      <ul>
        <li>
          "מעסיק <b>יכול להחליט באופן וולונטרי</b> להתייחס בדיווח גם לעובדי קבלן, ובלבד שיוצגו כפילוח נפרד בדוח."
        </li>
        <li>
          "<b>מומלץ לציין</b> את מספר העובדים והעובדות במקום העבודה, <b>אם כי חוק שכר שווה אינו מחייב זאת</b> במפורש."
        </li>
        <li>
          "לעניין תגמול הוני (אופציות …), <b>נמליץ</b> למעסיק להכליל רכיב זה בחישוב שכר העובד.ת וכן, <b>ככל שישנם פערי שכר שיכולים להיות מוסברים על ידי תגמול הוני, נמליץ</b> למעסיק להתייחס לכך בדיווח."
        </li>
        <li>
          השכר המוצג בדיווח - "<b>נמליץ</b> כי בדיווח יוצגו 3 רמות שכר: שכר קובע, שכר ברוטו, שכר ברוטו + הפקדות מעסיק".
        </li>
        <li>
          פילוח העובדים - "חלוקת העובדים במקום העבודה לקבוצות לפי סוגי עובדים, סוגי משרות או סוגי דירוגים, והכול בהתאם לסוג ולאופי מקום העבודה. <b>הגדרה זו מקנה לכל מעסיק שיקול דעת בקביעת פילוח העובדות והעובדים</b>."
        </li>
        <li>
          "בקבוצת פילוח בה לא ניתן לבחון את פערי השכר, משום שלמשל בקבוצה זו נכללים רק עובדים ממין אחד או שמספר העובדים בקבוצה קטן שיש בו כדי לפגוע בהגנת הפרטיות, אנו <b>נמליץ לרשום הסבר מבאר לצד זאת</b>".
        </li>
      </ul>
    </div>
  )
}

export default A3;
