export const R16 = {
    "category":"דוחות נוספים",
    "reports":[
        {
            "name":"עשות אשקלון",
            "engName":"ashot",
            "year":2021,
            "reportUrl":"https://ashot.co.il/wp-content/uploads/2022/05/%D7%97%D7%95%D7%A7-%D7%A9%D7%9B%D7%A8-%D7%A9%D7%95%D7%95%D7%94-%D7%93%D7%95%D7%97-%D7%A4%D7%95%D7%9E%D7%91%D7%99-2021-2.pdf",
            "originUrl":"",
            "reportPath":"עשות_אשקלון_2021.pdf",
            "missing":false,
            "note":""
        },
        {
            "name":"ישקר",
            "engName":"iscar",
            "year":2022,
            "reportUrl":"https://www.iscar.co.il/newarticles.aspx/countryid/34/newarticleid/12074",
            "originUrl":"",
            "reportPath":"ישקר_2022.pdf",
            "missing":false,
            "note":"",
			"mainFindings":"נמצא כי שכר התקן (שכר בסיס למשרה מלאה של 182 שעות בחודש) הממוצע למשרה מלאה של נשים במעמד גלובלי גבוה ב- 5% מזה של גברים (לעומת 3% בשנה שעברה) וטווח פערי השכר בקטגוריה זו נע בין 8.8% (-) ל 16.2% (+). בקבוצת העובדים במעמד חודשי, שכר התקן הממוצע של נשים נמוך ב- 3% מזה של הגברים (לעומת 5% בשנה שעברה) וטווח פערי השכר נעים בין 15.7% (-) ל- 6.1% (+)."
        },
        {
            "name":"אייס",
            "engName":"ace",
            "year":2022,
            "reportUrl":"https://www.ace.co.il/publicreports",
            "originUrl":"",
            "reportPath":"אייס_2022.pdf",
            "missing":false,
            "note":""
        },
        {
            "name":"רשת משען",
            "engName":"mishan",
            "year":2022,
            "reportUrl":"http://www.mishan.co.il/wp-content/uploads/2023/06/%D7%93%D7%95%D7%97-%D7%A4%D7%95%D7%9E%D7%91%D7%99-%D7%97%D7%95%D7%A7-%D7%A9%D7%9B%D7%A8-%D7%A9%D7%95%D7%95%D7%94-%D7%9E%D7%A9%D7%A2%D7%9F-2022.pdf",
            "originUrl":"",
            "reportPath":"רשת_משען_2022.pdf",
            "missing":false,
            "note":""
        },
        {
            "name":"פדקס אקספרס",
            "engName":"fedex",
            "year":2022,
            "reportUrl":"https://www.fedex.com/content/dam/fedex/eu-europe/downloads/IL_HE_2023_6_master_pdf_NA_Public_report_Equal_Pay_FedEx_Express_IL_marketing.pdf",
            "originUrl":"",
            "reportPath":"פדקס_אקספרס_2022.pdf",
            "missing":false,
            "note":""
        },
        {
            "name":"האגודה לקידום החינוך, ירושלים",
            "engName":"kidum-edu",
            "year":2021,
            "reportUrl":"https://www.kidum-edu.org.il/en/wp-content/uploads/2022/06/%D7%93%D7%95%D7%97-%D7%A4%D7%95%D7%9E%D7%91%D7%99.pdf",
            "originUrl":"",
            "reportPath":"האגודה_לקידום_החינוך,_ירושלים_2021.pdf",
            "missing":false,
            "note":""
        },
        {
            "name":"רשת אמית",
            "engName":"amit",
            "year":2022,
            "reportUrl":"https://amit.org.il/%D7%93%D7%95%D7%97-%D7%97%D7%95%D7%A7-%D7%A9%D7%9B%D7%A8-%D7%A9%D7%95%D7%95%D7%94/",
            "originUrl":"",
            "reportPath":"",
            "missing":false,
            "note":"html"
        },
        {
            "name":"ויצו",
            "engName":"wizo",
            "year":2022,
            "reportUrl":"http://www.wizo.org.il/page_35847",
            "originUrl":"",
            "reportPath":"ויצו_2022.pdf",
            "missing":false,
            "note":""
        },
        {
            "name":"מילגם",
            "engName":"milgam",
            "year":2021,
            "reportUrl":"https://www.milgam.co.il/vault/files/report21.pdf",
            "originUrl":"",
            "reportPath":"מילגם_2021.pdf",
            "missing":false,
            "note":"",
			"mainFindings":"הממוצע המשוקלל של פערי השכר הוא 0.04% לטובת הגברים."
        },
        {
            "name":"מילגם",
            "engName":"milgam",
            "year":2022,
            "reportUrl":"https://www.milgam.co.il/vault/files/2022-milgam.pdf",
            "originUrl":"",
            "reportPath":"מילגם_2022.pdf",
            "missing":false,
            "note":"",
			"mainFindings":"הממוצע המשוקלל של פערי השכר הוא 0.005% לטובת הנשים."
        },
        {
            "name":"קבוצת הולמס פלייס",
            "engName":"holmesplace",
            "year":2022,
            "reportUrl":"https://www.holmesplace.co.il/wp-content/uploads/2023/08/Public-Report-Commissionaire-Designed-Version.pdf",
            "originUrl":"https://www.holmesplace.co.il/equal-pay/",
            "reportPath":"קבוצת_הולמס_פלייס_2022.pdf",
            "missing":false,
            "note":""
        },
        {
            "name":"תמנון",
            "engName":"tamnoon",
            "year":2022,
            "reportUrl":"https://tamnoon.com/wp-content/uploads/2023/06/%D7%93%D7%95%D7%97-%D7%9C%D7%A4%D7%A8%D7%A1%D7%95%D7%9D-%D7%A9%D7%9B%D7%A8-%D7%A9%D7%95%D7%95%D7%94-2022-%D7%91%D7%90%D7%AA%D7%A8-%D7%94%D7%91%D7%A0%D7%A7-19.06.2023-1.pdf",
            "originUrl":"",
            "reportPath":"תמנון_2022.pdf",
            "missing":false,
            "note":""
        },
        {
            "name":"מ.ל.ר.ן",
            "engName":"mlrn",
            "year":2021,
            "reportUrl":"https://mlrn.co.il/wp-content/uploads/2023/02/%D7%93%D7%95%D7%97-%D7%A9%D7%9B%D7%A8-%D7%A9%D7%95%D7%95%D7%94-%D7%9C%D7%A2%D7%95%D7%91%D7%93%D7%AA-%D7%95%D7%9C%D7%A2%D7%95%D7%91%D7%93-%D7%9C%D7%A9%D7%A0%D7%AA-2021-%D7%A0%D7%92%D7%99%D7%A9.pdf",
            "originUrl":"",
            "reportPath":"מ.ל.ר.ן_2021.pdf",
            "missing":false,
            "note":""
        },
        {
            "name":"מ.ל.ר.ן",
            "engName":"mlrn",
            "year":2022,
            "reportUrl":"https://mlrn.co.il/wp-content/uploads/2023/06/1687294280_%D7%93%D7%95%D7%97_%D7%A9%D7%9B%D7%A8_%D7%A9%D7%95%D7%95%D7%94_2022_20062023.pdf",
            "originUrl":"",
            "reportPath":"מ.ל.ר.ן_2022.pdf",
            "missing":false,
            "note":""
        },
        {
            "name":"נטפים",
            "engName":"netafim",
            "year":2021,
            "reportUrl":"https://www.netafim.co.il/49b6e0/globalassets/--_a4.pdf",
            "originUrl":"",
            "reportPath":"",
            "missing":true,
            "note":"broken"
        },
        {
            "name":"אדמה",
            "engName":"adama",
            "year":2021,
            "reportUrl":"https://www.adama.com/sites/adama_global/files/documents/2022-06/Equal%20Pay%20Report%20%28Israel%20Employees%20only%29.pdf",
            "originUrl":"",
            "reportPath":"אדמה_2021.pdf",
            "missing":false,
            "note":""
        },
        {
            "name":"אדמה",
            "engName":"adama",
            "year":2022,
            "reportUrl":"https://www.adama.com/sites/adama_global/files/documents/2023-05/Equal%20Pay%20Report%202022.pdf",
            "originUrl":"",
            "reportPath":"אדמה_2022.pdf",
            "missing":false,
            "note":""
        },
        {
            "name":"צים",
            "engName":"zim",
            "year":2022,
            "reportUrl":"https://www.zim.com/assets/vjrlxuhb/public_report_on_equal_gender_wages_israel_2022.pdf",
            "originUrl":"",
            "reportPath":"צים_2022.pdf",
            "missing":false,
            "note":""
        },
        {
            "name":"צוות 3",
            "engName":"team3",
            "year":2022,
            "reportUrl":"https://www.team3.co.il/%D7%97%D7%95%D7%A7-%D7%A9%D7%9B%D7%A8-%D7%A9%D7%95%D7%95%D7%94-%D7%9C%D7%A2%D7%95%D7%91%D7%93%D7%AA-%D7%95%D7%9C%D7%A2%D7%95%D7%91%D7%93/",
            "originUrl":"",
            "reportPath":"צוות_3_2022.pdf",
            "missing":false,
            "note":""
        },
        {
            "name":"בית אקשטיין",
            "engName":"b-e",
            "year":2022,
            "reportUrl":"https://b-e.org.il/%d7%90%d7%95%d7%93%d7%95%d7%aa/%d7%93%d7%95%d7%97-%d7%a9%d7%9b%d7%a8-%d7%a9%d7%95%d7%95%d7%94-%d7%9c%d7%a2%d7%95%d7%91%d7%93-%d7%95%d7%a2%d7%95%d7%91%d7%93%d7%aa/",
            "originUrl":"",
            "reportPath":"בית_אקשטיין_2022.pdf",
            "missing":false,
            "note":""
        },
        {
            "name":"צומת ספרים",
            "engName":"booknet",
            "year":2022,
            "reportUrl":"https://www.booknet.co.il/%d7%93%d7%95%d7%97-%d7%a9%d7%9b%d7%a8-%d7%a9%d7%95%d7%95%d7%94",
            "originUrl":"",
            "reportPath":"",
            "missing":false,
            "note":"html"
        },
        {
            "name":"דלוייט",
            "engName":"deloitte",
            "year":2022,
            "reportUrl":"https://www2.deloitte.com/content/dam/Deloitte/il/Documents/general/1623.pdf",
            "originUrl":"",
            "reportPath":"דלוייט_2022.pdf",
            "missing":false,
            "note":"",
			"mainFindings":"יותר ממחצית (56%) מההון האנושי בפירמה הן נשים, 48% מהדרג הניהולי מורכב מנשים, 4 מתוך 5 ראשי חטיבות העסקיות שלנו הן נשים, וההנהלה שלנו מורכבת מרוב נשי –7 מתוך 13 חברי וחברות הנהלה, הן נשים. בדיקתנו ב- Deloitte ישראל העלתה כי הממוצע המשוקלל של הפער בכל הקבוצות בהתייחס לשכר הקובע לפיצויים הינו +1.8% ושל הפער בשכר ברוטו הינו 3.9%-."
        },
        {
            "name":"בית בכפר",
            "engName":"bayit-bakfar",
            "year":2022,
            "reportUrl":"https://www.bayit-bakfar.co.il/wp-content/uploads/2023/05/%D7%97%D7%95%D7%A7-%D7%A9%D7%9B%D7%A8-%D7%A9%D7%95%D7%95%D7%94-%D7%93%D7%95%D7%97-%D7%A4%D7%95%D7%9E%D7%91%D7%99-%D7%A9%D7%A0%D7%AA%D7%99-%D7%91%D7%99%D7%AA-%D7%91%D7%9B%D7%A4%D7%A8-2022.pdf",
            "originUrl":"",
            "reportPath":"בית_בכפר_2022.pdf",
            "missing":false,
            "note":""
        },
        {
            "name":"אנוש",
            "engName":"enosh",
            "year":2021,
            "reportUrl":"https://www.enosh.org.il/wp-content/uploads/2022/06/%D7%93%D7%95%D7%97-%D7%A9%D7%9B%D7%A8-%D7%A9%D7%95%D7%95%D7%94-%D7%9C%D7%A2%D7%95%D7%91%D7%93%D7%AA-%D7%95%D7%9C%D7%A2%D7%95%D7%91%D7%93-%D7%9C%D7%A9%D7%A0%D7%AA-2021-%D7%A2%D7%9E%D7%95%D7%AA%D7%AA-%D7%90%D7%A0%D7%95%D7%A9.pdf",
            "originUrl":"https://www.enosh.org.il/%D7%9E%D7%A1%D7%9E%D7%9B%D7%99-%D7%94%D7%A2%D7%9E%D7%95%D7%AA%D7%94/",
            "reportPath":"אנוש_2021.pdf",
            "missing":false,
            "note":""
        },
        {
            "name":"אנוש",
            "engName":"enosh",
            "year":2022,
            "reportUrl":"https://www.enosh.org.il/wp-content/uploads/2023/05/%D7%93%D7%95%D7%97-%D7%A9%D7%9B%D7%A8-%D7%A9%D7%95%D7%95%D7%94-%D7%9E%D7%9B%D7%AA%D7%91-%D7%9C%D7%A2%D7%95%D7%91%D7%93%D7%99%D7%9D-%D7%95%D7%94%D7%A2%D7%95%D7%91%D7%93%D7%95%D7%AA.pdf",
            "originUrl":"https://www.enosh.org.il/%D7%9E%D7%A1%D7%9E%D7%9B%D7%99-%D7%94%D7%A2%D7%9E%D7%95%D7%AA%D7%94/",
            "reportPath":"אנוש_2022.pdf",
            "missing":false,
            "note":""
        },
        {
            "name":"נעמן",
            "engName":"naaman",
            "year":2022,
            "reportUrl":"https://www.naamanp.co.il/equal_pay_law",
            "originUrl":"",
            "reportPath":"נעמן_2022.pdf",
            "missing":false,
            "note":""
        },
        {
            "name":"מפעלי ים המלח",
            "engName":"ICL",
            "year":2021,
            "reportUrl":"https://he.icl-group.com/wp-content/uploads/2022/06/%D7%93%D7%95%D7%97%D7%95%D7%AA-%D7%A4%D7%95%D7%9E%D7%91%D7%99%D7%99%D7%9D-%D7%A9%D7%9B%D7%A8-%D7%A9%D7%95%D7%95%D7%94-%D7%9C%D7%A2%D7%95%D7%91%D7%93%D7%AA-%D7%95%D7%A2%D7%95%D7%91%D7%93-1.pdf",
            "originUrl":"",
            "reportPath":"מפעלי_ים_המלח_2021.pdf",
            "missing":false,
            "note":""
        },
        {
            "name":"מפעלי ים המלח",
            "engName":"ICL",
            "year":2022,
            "reportUrl":"https://he.icl-group.com/wp-content/uploads/2023/05/1684332096_2023%D7%93%D7%95%D7%97%D7%95%D7%AA_%D7%A4%D7%95%D7%9E%D7%91%D7%99%D7%99%D7%9D_%D7%A9%D7%9B%D7%A8_%D7%A9%D7%95%D7%95%D7%94_%D7%9C%D7%A2%D7%95%D7%91%D7%93%D7%AA_%D7%95%D7%A2%D7%95%D7%91%D7%93.pdf",
            "originUrl":"",
            "reportPath":"מפעלי_ים_המלח_2022.pdf",
            "missing":false,
            "note":""
        },
        {
            "name":"טאואר סמיקונדקטור",
            "engName":"Tower",
            "year":2021,
            "reportUrl":"https://towersemi.com/wp-content/uploads/2022/06/%D7%98%D7%90%D7%95%D7%90%D7%A8-%D7%93%D7%95%D7%97-%D7%A9%D7%9B%D7%A8-%D7%A9%D7%95%D7%95%D7%94-%D7%9C%D7%A2%D7%95%D7%91%D7%93%D7%AA-%D7%95%D7%9C%D7%A2%D7%95%D7%91%D7%93-%D7%9E%D7%90%D7%99-2022-%D7%A0%D7%92%D7%99%D7%A9%D7%95%D7%AA_AC.pdf",
            "originUrl":"",
            "reportPath":"טאואר_סמיקונדקטור_2021.pdf",
            "missing":false,
            "note":""
        },
        {
            "name":"הג'וינט",
            "engName":"thejoint",
            "year":2022,
            "reportUrl":"https://www.thejoint.org.il/news/%D7%92%D7%95%D7%99%D7%A0%D7%98-%D7%99%D7%A9%D7%A8%D7%90%D7%9C-%D7%93%D7%95%D7%97-%D7%A9%D7%9B%D7%A8-%D7%A9%D7%95%D7%95%D7%94-%D7%9C%D7%A2%D7%95%D7%91%D7%93%D7%AA-%D7%95%D7%9C%D7%A2%D7%95%D7%91/",
            "originUrl":"",
            "reportPath":"הגוינט_2022.pdf",
            "missing":false,
            "note":""
        },
        {
            "name":"הארץ",
            "engName":"haaretz",
            "year":2022,
            "reportUrl":"https://storage.googleapis.com/haaretz-cms-prod/0e/b6/bf9bc6bc4b2685a81ede8c64c4d4/public-pay-report.pdf",
            "originUrl":"",
            "reportPath":"הארץ_2022.pdf",
            "missing":false,
            "note":""
        }
    ]
};
