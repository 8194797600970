export const R2 = {
    "category":"משרדי הממשלה",
    "reports":[
        {
            "name":"משרדי הממשלה - דוח מלא",
            "engName":"Public",
            "year":2021,
            "page":4,
            "reportUrl":"https://www.gov.il/BlobFolder/reports/salary-supervisor-report-gender-wage-differentials-2021-main-findings/he/salary-supervisor-reports_salary-supervisor-report-gender-wage-differentials-2021-full-version.pdf",
            "originUrl":"https://www.gov.il/he/Departments/publications/reports/salary-supervisor-report-gender-wage-differentials-2021-main-findings",
            "morePages":[
                {
                    "name":"המשרד לביטחון פנים",
                    "page":4
                },
                {
                    "name":"המשרד להגנת הסביבה",
                    "page":4
                },
                {
                    "name":"המשרד לפיתוח הפריפריה, הנגב והגליל",
                    "page":5
                },
                {
                    "name":"המשרד לשירותי דת",
                    "page":6
                },
                {
                    "name":"הנהלת בתי המשפט",
                    "page":7
                },
                {
                    "name":"הרשות הממשלתית למים ולביוב",
                    "page":8
                },
                {
                    "name":"זרוע העבודה",
                    "page":9
                },
                {
                    "name":"מנהל המחקר החקלאי",
                    "page":10
                },
                {
                    "name":"משרד האוצר",
                    "page":10
                },
                {
                    "name":"משרד האנרגיה",
                    "page":11
                },
                {
                    "name":"משרד הבינוי והשיכון",
                    "page":12
                },
                {
                    "name":"משרד הבריאות",
                    "page":13
                },
                {
                    "name":"משרד החדשנות, המדע, הטכנולוגיה, התרבות והספורט",
                    "page":14
                },
                {
                    "name":"משרד החוץ",
                    "page":15
                },
                {
                    "name":"משרד החינוך",
                    "page":16
                },
                {
                    "name":"משרד החקלאות ופיתוח הכפר",
                    "page":17
                },
                {
                    "name":"משרד הכלכלה והתעשייה",
                    "page":18
                },
                {
                    "name":"משרד המשפטים",
                    "page":19
                },
                {
                    "name":"משרד העלייה והקליטה",
                    "page":20
                },
                {
                    "name":"משרד הפנים",
                    "page":21
                },
                {
                    "name":"משרד הרווחה והביטחון החברתי",
                    "page":22
                },
                {
                    "name":"משרד התחבורה והבטיחות בדרכים",
                    "page":23
                },
                {
                    "name":"משרד התיירות",
                    "page":24
                },
                {
                    "name":"משרד התקשורת",
                    "page":24
                },
                {
                    "name":"משרד ראש הממשלה",
                    "page":25
                },
                {
                    "name":"רשות האוכלוסין וההגירה",
                    "page":26
                },
                {
                    "name":"רשות החשמל",
                    "page":27
                },
                {
                    "name":"רשות המסים בישראל",
                    "page":28
                }
            ],
            "reportPath":"משרדי_הממשלה_-_דוח_מלא_2021.pdf",
            "missing":false,
            "note":""
        }
    ]
};
