export const R6 = {
    "category":"הייטק וטכנולוגיה",
    "reports":[
        {
            "name":"נס",
            "engName":"Ness",
            "year":2021,
            "reportUrl":"https://www.ness-tech.co.il/media/3002/public-report.pdf",
            "originUrl":"",
            "reportPath":"נס_2021.pdf",
            "missing":false,
            "note":"",
			"mainFindings":"72% מכלל העובדות והעובדים בחברה נמצאים בקבוצות בהן פערי השכר הם מתחת ל - 5% לכאן או לכאן. 82% מכלל העובדות והעובדים בחברה נמצאים בקבוצות בהן הפערים מתחת ל- 10% לכאן או לכאן. 11% מכלל העובדות והעובדים בחברה נמצאים בקבוצות חד מגדריות, קבוצות בעלות מגדר דומיננטי או קבוצות קטנות מאוד אשר בהן לא ניתן לבצע השוואה או לחשוף את הפער, בשל חשש לפגיעה בפרטיות. החברה מעסיקה כ- 4000 עובדים ועובדות, כמחציתם (46%) נשים."
        },
        {
            "name":"נס",
            "engName":"Ness",
            "year":2022,
            "reportUrl":"https://www.ness-tech.co.il/media/3208/public-report-ness-2022.pdf",
            "originUrl":"",
            "reportPath":"נס_2022.pdf",
            "missing":false,
            "note":"",
			"mainFindings":"בכל קבוצות הפילוח שנדגמו נמצא כי פערי ממוצעי השכר הינם נמוכים מ-7% לטובת הגברים או הנשים. מתוך כך, חשוב לציין שב-88% מכלל הקבוצות הללו נמצאו פערים הנמוכים מ-5% לטובת הגברים או הנשים, ול-82% מכלל הקבוצות נמצאו פערים הנמוכים מ-3%+/-."
        },
        {
            "name":"מוסד הטכניון למחקר ופיתוח",
            "engName":"trdf",
            "year":2021,
            "reportUrl":"https://hr.trdf.co.il/images/%D7%93%D7%95%D7%97%20%D7%A4%D7%95%D7%9E%D7%91%D7%99%20%D7%97%D7%95%D7%A7%20%D7%A9%D7%9B%D7%A8%20%D7%A9%D7%95%D7%95%D7%94%20TRDF%202022%20(1).pdf",
            "originUrl":"",
            "reportPath":"מוסד_הטכניון_למחקר_ופיתוח_2021.pdf",
            "missing":false,
            "note":""
        },
        {
            "name":"מוסד הטכניון למחקר ופיתוח",
            "engName":"trdf",
            "year":2022,
            "reportUrl":"https://hr.trdf.co.il/prdPics/files/1568_103854_content_files_3.pdf",
            "originUrl":"",
            "reportPath":"מוסד_הטכניון_למחקר_ופיתוח_2022.pdf",
            "missing":false,
            "note":""
        },
        {
            "name":"מלם",
            "engName":"Malam team",
            "year":2021,
            "reportUrl":"https://www.malamteam.com/wp-content/uploads/2022/06/%D7%97%D7%95%D7%A7-%D7%A9%D7%9B%D7%A8-%D7%A9%D7%95%D7%95%D7%94-%D7%93%D7%95%D7%97-%D7%A1%D7%95%D7%A4%D7%99-%D7%9E%D7%9C%D7%9D-%D7%AA%D7%99%D7%9D-2021.pdf",
            "originUrl":"",
            "reportPath":"מלם_2021.pdf",
            "missing":false,
            "note":"",
			"mainFindings":"מלם תים דוגלת בשיוויון מגדרי, שוויון הזדמנויות והעסקה וכיום מעסיקה כ-43% נשים. מניתוח פערי השכר בקבוצות הפילוח עולה כי פערי שכר בין גרבים לנשים הינם עד 10% לכל היותר, ומתוכם כ-68% נמצאים עם פער שכר של מתחת -5%."
        },
        {
            "name":"מלם",
            "engName":"Malam team",
            "year":2022,
            "reportUrl":"https://www.malamteam.com/wp-content/uploads/2023/06/%D7%93%D7%95%D7%97-%D7%A4%D7%95%D7%9E%D7%91%D7%99-%D7%97%D7%95%D7%A7-%D7%A9%D7%9B%D7%A8-%D7%A9%D7%95%D7%95%D7%94-2022-%D7%A7%D7%91%D7%95%D7%A6%D7%AA-%D7%9E%D7%9C%D7%9D-%D7%AA%D7%99%D7%9D.pdf",
            "originUrl":"",
            "reportPath":"מלם_2022.pdf",
            "missing":false,
            "note":"",
			"mainFindings":"מלם תים דוגלת בשיוויון מגדרי ושיוויון הזדמנויות והעסקה ומעסיקה כ-48% נשים. מניתוח פערי השכר בקבוצות הפילוח עולה כי פערי שכר בין גרבים לנשים הינם עד 10% לכל היותר, ומתוכם כ-71% נמצאים עם פער שכר של מתחת -5%."
        },
        {
            "name":"מיקרוסופט",
            "engName":"Microsoft",
            "year":2021,
            "reportUrl":"https://msrndcdn360.blob.core.windows.net/rnd/Publications/2021%20Gender%20Pay%20Gap%20Report%20for%20Microsoft%20Israel%20R%26D%20Center.pdf",
            "originUrl":"",
            "reportPath":"מיקרוסופט_2021.pdf",
            "missing":false,
            "note":""
        },
        {
            "name":"מיקרוסופט",
            "engName":"Microsoft",
            "year":2022,
            "reportUrl":"https://msrndcdn360.blob.core.windows.net/rnd/Publications/2342342209885757758885.pdf",
            "originUrl":"",
            "reportPath":"מיקרוסופט_2022.pdf",
            "missing":false,
            "note":""
        },
        {
            "name":"אמזון",
            "engName":"Amazon",
            "year":2021,
            "reportUrl":"https://assets.aboutamazon.com/51/2e/44ce0a0544738e5aa1df3d648ed0/amazon-israel-gender-pay-gap-public-report-2021.pdf",
            "originUrl":"",
            "reportPath":"אמזון_2021.pdf",
            "missing":false,
            "note":""
        },
        {
            "name":"אמזון",
            "engName":"Amazon",
            "year":2022,
            "reportUrl":"https://assets.aboutamazon.com/94/53/8d5f09fa411880ed43058c83d890/amazon-israel-gender-pay-gap-public-report-2022.pdf",
            "originUrl":"",
            "reportPath":"אמזון_2022.pdf",
            "missing":false,
            "note":""
        },
        {
            "name":"גוגל",
            "engName":"Google",
            "year":2021,
            "reportUrl":"https://static.googleusercontent.com/media/about.google/en//belonging/diversity-annual-report/2022/static/pdfs/google_israel_2021_binary_gender_pay_gap_report.pdf",
            "originUrl":"",
            "reportPath":"גוגל_2021.pdf",
            "missing":false,
            "note":"",
			"mainFindings":"בין ינואר 2020 ל1- ביוני 2022, גדל חלקן של העובדות ב-Google ישראל מ-27.2% ל-.29.3%. הדו'ח להלן מבוסס על נתוני שנת 2021, כולל 93% מעובדי גוגל ישראל ומראה פער ממוצע של 4%- לטובת הגברים כאשר 61% מהנשים ו-50% מהגברים נמצאים מתחת לשכר הממוצע."
        },
        {
            "name":"גוגל",
            "engName":"Google",
            "year":2022,
            "reportUrl":"https://static.googleusercontent.com/media/about.google/en//belonging/diversity-annual-report/2023/static/pdfs/google_israel_2022_binary_gender_pay_gap_report.pdf",
            "originUrl":"",
            "reportPath":"גוגל_2022.pdf",
            "missing":false,
            "note":"",
			"mainFindings":"יותר מ-2,000 אנשים עובדים היום ב-Google בישראל, ובזמן שכוח העבודה שלנו מכיל אנשים מתפקידים ורקעים שונים, כולנו שותפים לאותה משימה. בין דצמבר 2021 לדצמבר 2022, הגדלנו את ייצוג הנשים שלנו מ-28.8% ל-29.4%. הגדלנו את ייצוג הדירקטוריות מ-23.3% ל-.29.7%. הדו'ח להלן מבוסס על נתוני שנת 2022 ,כולל 97% מעובדי Google ישראל ומראה פער ממוצע של 7%- לטובת הגברים כאשר 63% מהנשים ו-47% מהגברים נמצאים מתחת לשכר הממוצע."
        },
        {
            "name":"מטא",
            "engName":"Meta",
            "year":2021,
            "reportUrl":"https://s21.q4cdn.com/399680738/files/doc_downloads/2023/external-results-2021.pdf",
            "originUrl":"",
            "reportPath":"מטא_2021.pdf",
            "missing":false,
            "note":""
        },
        {
            "name":"מטא",
            "engName":"Meta",
            "year":2022,
            "reportUrl":"https://s21.q4cdn.com/399680738/files/doc_downloads/2023/05/external-results-2022.pdf",
            "originUrl":"",
            "reportPath":"מטא_2022.pdf",
            "missing":false,
            "note":""
        },
        {
            "name":"נייס",
            "engName":"Nice",
            "year":2021,
            "reportUrl":"https://www.nice.com/-/media/niceincontact/page-content/company/corporate-responsibility/gender-pay-gap-report3.ashx?la=en&rev=68eb3167d9b2400faf350969cc3301a8&hash=3986A174CD798D1DDE688E4AA1628D11",
            "originUrl":"",
            "reportPath":"נייס_2021.pdf",
            "missing":false,
            "note":"",
			"mainFindings":"הפער הממוצע (בחישוב ממוצע משוקלל) בין הקבוצות הוא 0.4%."
        },
        {
            "name":"hp",
            "engName":"hp",
            "year":2022,
            "reportUrl":"https://h20195.www2.hp.com/v2/GetDocument.aspx?docname=c08307401",
            "originUrl":"",
            "reportPath":"",
            "missing":false,
            "note":""
        },
        {
            "name":"מטריקס",
            "engName":"Matrix",
            "year":2021,
            "reportUrl":"https://www.matrix-globalservices.com/wp-content/uploads/Equal-Pay-Public-Report-2021.pdf",
            "originUrl":"",
            "reportPath":"מטריקס_2021.pdf",
            "missing":false,
            "note":"",
			"mainFindings":"אנו גאים לשתף כי בניתוח הנתונים בכל הקבוצות נצפו פערים של אחוזים בודדים בלבד, כאשר בחלק מהקבוצות הפערים נטו לטובת הגברים (לא יותר מ-9%) ובחלק לטובת הנשים (לא יותר מ-11%)."
        },
        {
            "name":"אלביט",
            "engName":"Elbit",
            "year":2021,
            "reportUrl":"https://elbitsystems.com/media/2021-Public-Report-under-Equal-Pay-for-Female-and-Male-Employees-accessible.pdf",
            "originUrl":"",
            "reportPath":"אלביט_2021.pdf",
            "missing":false,
            "note":""
        },
        {
            "name":"אלביט",
            "engName":"Elbit",
            "year":2022,
            "reportUrl":"https://elbitsystems.com/media/%D7%97%D7%95%D7%A7-%D7%A9%D7%9B%D7%A8-%D7%A9%D7%95%D7%95%D7%94-%D7%93%D7%95%D7%97-%D7%A4%D7%95%D7%9E%D7%91%D7%99-31.5-%D7%A0%D7%92%D7%99%D7%A9.pdf",
            "originUrl":"",
            "reportPath":"אלביט_2022.pdf",
            "missing":false,
            "note":""
        },
        {
            "name":"צ'ק פוינט",
            "engName":"checkpoint",
            "year":2022,
            "reportUrl":"https://www.checkpoint.com/downloads/company/esg-equal-pay-report-june-2022-hebrew.pdf",
            "originUrl":"",
            "reportPath":"צק_פוינט_2022.pdf",
            "missing":false,
            "note":"",
			"mainFindings":"בהסתכלות על הקבוצות עצמן מצאנו כי מספר הקבוצות בהן הפערים נטו לטובת הנשים שווה למספר הקבוצות בהן הפערים נטו לטובת הגברים, ככלל, פערים אלו עמדו על אחוזים בודדים ולא מעבר ל-3.5%, מקור הפערים נובע מהבדלי וותק, ניסיון או הישגים מקצועיים אישיים. כמו כן, בהנהלה הבכירה של צ'ק פוינט בישראל חברות 27% נשים, ושכרן הממוצע גבוה ב-26% מזה של הגברים."
        },
        {
            "name":"אמדוקס",
            "engName":"amdocs",
            "year":2021,
            "reportUrl":"https://www.amdocs.com/sites/default/files/2022-06/israel-equal-pay-public-report-2021.pdf",
            "originUrl":"",
            "reportPath":"אמדוקס_2021.pdf",
            "missing":false,
            "note":"",
			"mainFindings":"נשים מהוות קרוב למחצית מכלל העובדים, 37% בתפקידים הטכנולוגיים ו-35% בתפקידי ניהול - מספרים גבוהים לאין שיעור ביחס לתעשיית ההייטק. הפער הממוצע המשוקלל בין שכר הנשים לגברים, בהתאם להוראות הדיווח של החוק, עומד על 2% לטובת גברים. בשליש מהקבוצות בניתוח לא נמצאו כלל פערים , או נמצאו פערים לטובת נשים. בקרב 85% מעובדות ועובדי אמדוקס הפערים שנמצאו הינם נמוכים מאוד."
        },
        {
            "name":"סיילספורס",
            "engName":"salesforce",
            "year":2022,
            "reportUrl":"https://www.salesforce.com/il/blog/2023/Israel_wage_gap_data_report_2023.html",
            "originUrl":"",
            "reportPath":"סיילספורס_2022.pdf",
            "missing":false,
            "note":""
        }
    ]
};
