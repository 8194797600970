import React from 'react';
import './Answer.css';


const C1 = () => {
  return (
    <div className="answer">
      <p>
        אין כזו. לא אכיפה על עצם פרסום הדוח, ולא על עצם פערי השכר. ככתוב ב
        <a href='http://fs.knesset.gov.il/23/law/23_lst_570274.docx'
           className="link"
           target='_blank' rel="noopener noreferrer">הצעת החוק</a>
        &nbsp;"המודל המוצע מסתמך בעיקרו על רגולציה עצמית. כך, בשלב זה הצעת החוק אינה כוללת סנקציה כספית או אחרת על מעסיקים שיציגו פערי שכר מגדריים בארגון. יחד עם זאת, לעצם איסוף החומרים, הכנת הדו"ח השנתי ופרסומו תהיה השפעה שתניע את החברות לפעולה ולשינוי, הן בשל שיקולים פנימיים והן בשל לחץ חיצוני. המודל המוצע מבוסס במהותו על המודל הקיים באנגליה משנת 2017."
        <br/>
        חוסר אכיפה זו מאפשר לחברות להתייחס לחובת הפרסום הזו כלא יותר ממטרד ולא יוצר את התנאים הנדרשים לשינוי אמיתי.
      </p>
    </div>
  )
}

export default C1;
