export const R10 = {
    "category":"פארמה ורפואה",
    "reports":[
        {
            "name":"טבע",
            "engName":"teva",
            "year":2022,
            "reportUrl":"https://www.teva.co.il/globalassets/teva-il-scs-vision/teva-il-scs-vision-files/about-teva/equal-pay-law.pdf",
            "originUrl":"https://www.teva.co.il/about-teva/csr/equal-payment/",
            "reportPath":"טבע_2022.pdf",
            "missing":false,
            "note":"",
			"mainFindings":"בשתיים מהקבוצות שהגדרנו ממוצע השכר ברוטו למס של נשים גבוה מעט (בכ-1%) מזה של הגברים, בעוד שבקבוצה אחת (ייצור) נמצא כי ממוצע השכר ברוטו של הגברים גבוה (ב-8.9%) מזה של הנשים."
        },
        {
            "name":"ש.ל.ה שירותי רפואה",
            "engName":"clalitaesthetics",
            "year":2021,
            "reportUrl":"https://www.clalitaesthetics.co.il/tm-content/uploads/2022/09/45213_paarey-sahar-ovdum.pdf",
            "originUrl":"",
            "reportPath":"ש.ל.ה_שירותי_רפואה_2021.pdf",
            "missing":false,
            "note":""
        },
        {
            "name":"אסותא",
            "engName":"assuta",
            "year":2022,
            "reportUrl":"https://www.assuta.co.il/files/2022/Public_Report.pdf",
            "originUrl":"https://www.assuta.co.il/about_assuta/corporate_responsibility/public_report_2022/",
            "reportPath":"אסותא_2022.pdf",
            "missing":false,
            "note":""
        },
        {
            "name":"לאומית",
            "engName":"leumit",
            "year":2022,
            "reportUrl":"https://www.leumit.co.il/heb/Useful/jobs/info/articlegalleryitem,5585/",
            "originUrl":"",
            "reportPath":"",
            "missing":false,
            "note":"html"
        },
        {
            "name":"קרן מכבי - מכבי טבעי",
            "engName":"maccabitivi",
            "year":2022,
            "reportUrl":"https://www.maccabitivi.co.il/media/zormr1g5/%D7%93%D7%95%D7%97-%D7%A4%D7%95%D7%9E%D7%91%D7%99-%D7%93%D7%95%D7%97-%D7%A9%D7%9B%D7%A8-%D7%A9%D7%95%D7%95%D7%94-2022.pdf",
            "originUrl":"",
            "reportPath":"קרן_מכבי_-_מכבי_טבעי_2022.pdf",
            "missing":false,
            "note":""
        },
        {
            "name":"מכבי דנט",
            "engName":"maccabi-dent",
            "year":2021,
            "reportUrl":"https://maccabi-dent.com/wp-content/uploads/2022/06/%D7%97%D7%95%D7%A7-%D7%A9%D7%9B%D7%A8-%D7%A9%D7%95%D7%95%D7%94-%D7%9C%D7%A2%D7%95%D7%91%D7%93-%D7%95%D7%9C%D7%A2%D7%95%D7%91%D7%93%D7%AA.pdf",
            "originUrl":"",
            "reportPath":"מכבי_דנט_2021.pdf",
            "missing":false,
            "note":"",
			"mainFindings":"בשדרת הניהול ישנו רוב נשי של 55% ובקרב כלל העובדים כ – 80% נשים."
        },
        {
            "name":"נובולוג",
            "engName":"novolog",
            "year":2022,
            "reportUrl":"https://assets.website-files.com/61e3d989a957644834928d2e/6479aba094ab1e0add992641_Equality.pdf",
            "originUrl":"https://www.novolog.co.il/he/investors",
            "reportPath":"נובולוג_2022.pdf",
            "missing":false,
            "note":""
        }
    ]
};
