import React, { createContext, useContext, useEffect, useState } from 'react';

import { R1 } from './ReportJsons/R1';
import { R2 } from './ReportJsons/R2';
import { R3 } from './ReportJsons/R3';
import { R4 } from './ReportJsons/R4';
import { R5 } from './ReportJsons/R5';
import { R6 } from './ReportJsons/R6';
import { R7 } from './ReportJsons/R7';
import { R8 } from './ReportJsons/R8';
import { R9 } from './ReportJsons/R9';
import { R10 } from './ReportJsons/R10';
import { R11 } from './ReportJsons/R11';
import { R12 } from './ReportJsons/R12';
import { R13 } from './ReportJsons/R13';
import { R14 } from './ReportJsons/R14';
import { R15 } from './ReportJsons/R15';
import { R16 } from './ReportJsons/R16';

const ReportListServerlessContext = createContext();

const ReportListServerlessProvider = ({ children }) => {
  const [reportListLoaded, setReportListLoaded] = useState(false);
  const [reportsList, setReportsList] = useState([]);

  useEffect(() => {
    const jsonArray = [R1, R2, R3, R4, R5, R6, R7, R8, R9, R10, R11, R12, R13, R14, R15, R16];
    let allReports = [];
    let totalReports = 0;
    jsonArray.forEach(reportsJsonData => {
      reportsJsonData.reports.forEach((report) => {
        report.fullName = `${report.name} ${report.year}`;
        totalReports += 1;
      });
      reportsJsonData.reports.sort((reportA, reportB) => reportA.fullName.localeCompare(reportB.fullName));
      allReports.push(reportsJsonData);
     });

     let flatReportList = [];
     allReports.forEach(categoryItem => {
          const category = categoryItem.category;
          categoryItem.reports.forEach(report => {
            flatReportList.push({ ...report, category });
          });
      });

     // console.log("Feched", totalReports, "reports");
     setReportsList(flatReportList);
     setReportListLoaded(true);
  }, []);


  return (
    <ReportListServerlessContext.Provider value={{ reportListLoaded, reportsList }}>
      {children}
    </ReportListServerlessContext.Provider>
  );
};

const useReportList = () => {
  return useContext(ReportListServerlessContext);
};

export { ReportListServerlessProvider, useReportList };
