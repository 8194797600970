export const R15 = {
    "category":"תקשורת",
    "reports":[
        {
            "name":"בזק",
            "engName":"Bezeq",
            "year":2022,
            "reportUrl":"https://www.bezeq.co.il/media/4053086/public-report-2022-commissionaire-designed-version.pdf",
            "originUrl":"https://www.bezeq.co.il/gallerypress/01_06_2023/",
            "reportPath":"בזק_2022.pdf",
            "missing":false,
            "note":""
        },
        {
            "name":"בזק אונליין",
            "engName":"Bezeq",
            "year":2021,
            "reportUrl":"https://www.bezeqonline.co.il/wp-content/uploads/2022/06/%D7%93%D7%95%D7%97-%D7%A4%D7%95%D7%9E%D7%91%D7%99-%D7%A1%D7%95%D7%A4%D7%99-%D7%9C%D7%90%D7%AA%D7%A8.pdf",
            "originUrl":"",
            "reportPath":"בזק_אונליין_2021.pdf",
            "missing":false,
            "note":""
        },
        {
            "name":"בזק אונליין",
            "engName":"Bezeq",
            "year":2022,
            "reportUrl":"https://www.bezeqonline.co.il/wp-content/uploads/2023/06/2023.pdf",
            "originUrl":"",
            "reportPath":"בזק_אונליין_2022.pdf",
            "missing":false,
            "note":""
        },
        {
            "name":"פרטנר",
            "engName":"Partner",
            "year":2021,
            "reportUrl":"https://u.partner.co.il/media/puhm0pis/%D7%97%D7%95%D7%A7-%D7%A9%D7%9B%D7%A8-%D7%A9%D7%95%D7%95%D7%94-%D7%93%D7%95%D7%97-%D7%A4%D7%95%D7%9E%D7%91%D7%99-2021_.pdf",
            "originUrl":"",
            "reportPath":"פרטנר_2021.pdf",
            "missing":false,
            "note":"",
			"mainFindings":"היחס בין גברים לנשים בפרטנר שווה ועל כך גאוותינו. יתרה מכך, הדרג הניהולי הבכיר של החברה מאויש באופן שווה בין נשים לגברים. נמצא כי בחלק מהקבוצות ישנו פער חיובי בשכר לטובת נשים, כאשר הגבוה ביותר עומד על 11%. הפער הגבוה ביותר בשכר לטובת גברים עומד על 8%."
        },
        {
            "name":"פרטנר",
            "engName":"Partner",
            "year":2022,
            "reportUrl":"https://u.partner.co.il/media/0yjjqeav/%D7%97%D7%95%D7%A7-%D7%A9%D7%9B%D7%A8-%D7%A9%D7%95%D7%95%D7%94-%D7%93%D7%95%D7%97-%D7%A4%D7%95%D7%9E%D7%91%D7%99-2022.pdf",
            "originUrl":"",
            "reportPath":"פרטנר_2022.pdf",
            "missing":false,
            "note":"",
			"mainFindings":"היחס בין גברים לנשים בפרטנר שווה ועל כך גאוותינו. יתרה מכך, בדרג הניהולי הבכיר של החברה מכהנות 54% נשים. נמצא כי בחלק מהקבוצות ישנו פער חיובי בשכר לטובת נשים, כאשר הגבוה ביותר עומד על 10%. הפער הגבוה ביותר בשכר לטובת גברים עומד על 9%."
        },
        {
            "name":"פלאפון",
            "engName":"Pelephone",
            "year":2022,
            "reportUrl":"https://www.pelephone.co.il/digitalsite/uploadedfiles/%D7%A9%D7%99%D7%95%D7%95%D7%99%D7%95%D7%9F_%D7%94%D7%96%D7%93%D7%9E%D7%A0%D7%95%D7%AA_%D7%91%D7%A2%D7%91%D7%95%D7%93%D7%94-%D7%93%D7%95%D7%97_%D7%A4%D7%95%D7%9E%D7%91%D7%99.pdf",
            "originUrl":"",
            "reportPath":"פלאפון_2022.pdf",
            "missing":false,
            "note":""
        },
        {
            "name":"סלקום",
            "engName":"Cellcom",
            "year":2021,
            "reportUrl":"https://contentepi.cellcom.co.il/globalassets/pdf/public-report---01062022-rev1.pdf",
            "originUrl":"https://cellcom.co.il/production/Private/General_Info/public_report/",
            "reportPath":"סלקום_2021.pdf",
            "missing":false,
            "note":""
        }
    ]
};
