export const R1 = {
    "category":"גופים ציבוריים",
    "reports":[
        {
            "name":"הגופים הציבוריים (חברות ממשלתיות, רשויות מקומיות, עיריות, אוניברסיטאות, גופים במערכת הבריאות ועוד)",
            "engName":"Public",
            "year":2021,
            "page":5,
            "reportUrl":"https://www.gov.il/BlobFolder/dynamiccollectorresultitem/salary-supervisor-report-gender-wage-differentials-public-body-2021/he/salary-supervisor-reports_salary-supervisor-report-gender-wage-differentials-public-body-2021-full-versionfull-version.pdf",
            "originUrl":"https://www.gov.il/he/Departments/publications/reports/salary-supervisor-report-gender-wage-differentials-public-body-2021-main-findings",
            "morePages":[
                {
                    "name":"סוכנויות ביצוע ממשלתיות",
                    "page":11
                },
                {
                    "name":"חברות בטחוניות",
                    "page":14
                },
                {
                    "name":"חברות כלכליות",
                    "page":15
                },
                {
                    "name":"איגודי ערים",
                    "page":16
                },
                {
                    "name":"ועדות לתכנון ולבנייה",
                    "page":16
                },
                {
                    "name":"חברות עירוניות",
                    "page":18
                },
                {
                    "name":"מועצות דתיות",
                    "page":21
                },
                {
                    "name":"תאגידי מים",
                    "page":25
                },
                {
                    "name":"רשויות מקומיות (מועצות)",
                    "page":27
                },
                {
                    "name":"רשויות מקומיות (עיריות)",
                    "page":48
                },
                {
                    "name":"אוניברסיטאות",
                    "page":70
                },
                {
                    "name":"מכללות",
                    "page":74
                },
                {
                    "name":"גופים רפואיים",
                    "page":79
                },
                {
                    "name":"קופות חולים",
                    "page":81
                },
                {
                    "name":"שירותים אחרים במערכת הבריאות",
                    "page":83
                },
                {
                    "name":"תאגידים רווחה וכלכלה",
                    "page":86
                },
                {
                    "name":"תאגידים מורשת והשכלה",
                    "page":88
                },
                {
                    "name":"תאגידים אזוריים וחקלאיים",
                    "page":87
                },
                {
                    "name":"שונות",
                    "page":89
                }
            ],
            "reportPath":"גופים_ציבוריים_-_דוח_מלא_2021.pdf",
            "missing":false,
            "note":"",
			"mainFindings":"השכר הממוצע לחודש של העובדות ביחס לעובדים (שכר ברוטו למס) - 63%. השכר הממוצע לחודש של העובדות ביחס לעובדים (שכר קובע לפיצויי פיטורין) - 75%. בדוח זה לא נכללים 39 גופים מסיבות שונות כגון אי השלמת הדיווח."
        }
    ]
};
