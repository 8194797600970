import React from 'react';
import './Answer.css';

const B0 = () => {
  return (
    <div className="answer">
      <p>
      כחלק מחוק שכר שווה, החל משנת 2021 חלה חובת מעסיקים עם למעלה מ-518 עובדים לפרסם מידע בדבר פערי השכר בין גברים לנשים.
      <br />
      נכון להיום, הדוחות המפורסמים הולכים לאיבוד ברחבי הרשת ללא התייחסות. אין מקום אחד שמאגד את כל הדוחות ומנגיש אותם לציבור באופן מרוכז - לשם כך הפורטל הזה נוצר.
      </p>
    </div>
  )
}

export default B0;
