import React from 'react';
import './About.css';

import C0 from './Answers/C0'
import C1 from './Answers/C1'
import C2 from './Answers/C2'
import C3 from './Answers/C3'
import C4 from './Answers/C4'

const AboutTheLaw = () => {
  const qAndAData = [
    {
      question: 'על החוק',
      answer: <C0 />
    },
    {
      question: 'אכיפה?',
      answer: <C1 />
    },
    {
      question: 'מה רואים בדוחות?',
      answer: <C2 />
    },
    {
      question: 'מה בכל זאת ניתן להבין מהדוחות?',
      answer: <C3 />
    },
    {
      question: 'כמה ממצאים מהדוחות',
      answer: <C4 />
    },
  ];

  return (
    <div className="about-content">
      {qAndAData.map((item, index) => (
        <div key={index} className="qna-item">
          <h3 className="question">{item.question}</h3>
          {item.answer}
        </div>
      ))}
    </div>
  );
};

export default AboutTheLaw;
