import React from 'react';
import './Answer.css';

import { navigate } from '../Utils'

const B0 = () => {
  return (
    <div className="answer">
      <ul>
        <li>
          תשלחו עוד דוחות, ואם אתם רואים ממצאים חשובים, אנא ציינו.
        </li>
        <li>
          ייתכן וחלק מהקישורים לדוחות שבאתר כבר לא פעילים, מכיוון שחברות עדכנו את האתרים שלהן. אם אתם מזהים מזהה קישור שבור, ויכולים למצוא את הדוח במיקום החדש, בבקשה תשלחו את הקישור המעודכן.
        </li>
        <li>
          בכל ענין, אתם יכולים לפנות בעמוד&nbsp;
          <a href="#contact"
             className="link"
             onClick={() => navigate("contact")}>
             צרו קשר
          </a>.
        </li>
      </ul>
    </div>
  )
}

export default B0;
