import React, { useState } from 'react';
import './SearchableReportsTable.css';

import ReactGA from 'react-ga4';

import { useReportList } from '../Context/ReportListServerlessContext';
import { navigate, logOpenReport } from './Utils'

import { FaSort } from 'react-icons/fa'; // Import icons for sorting indicators
// Search related imports
import { Search } from '@mui/icons-material';
import { TextField, MenuItem, Select, InputLabel, FormControl, Box, InputAdornment, Grid } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

const DEFAULT_VALUE = 'הכל';

const ReportsTable = ({ reports, searchTerm, selectedYear, selectedCategory }) => {
  const [sortBy, setSortBy] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');

  const [expandedRows, setExpandedRows] = useState({});
  const toggleRow = (index) => {
    setExpandedRows((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const renderText = (text, index) => {
    const isExpanded = expandedRows[index];
    const maxLength = 100; // Threshold for truncation
    if (isExpanded || text.length <= maxLength) {
      return text;
    }
    return `${text.substring(0, maxLength)}...`;
  };

  let displayedReports = reports.filter(report => {
    const searchWord = searchTerm.toLowerCase();
    let match = false;
    // Search word match
    match = report.fullName.toLowerCase().includes(searchWord) ||
            report.engName.toLowerCase().includes(searchWord);
    // Year match
    if (selectedYear !== DEFAULT_VALUE) {
      match = match & report.year === selectedYear;
    }
    // Category match
    if (selectedCategory !== DEFAULT_VALUE) {
      match = match & report.category.toLowerCase() === selectedCategory;
    }
    return match;
  });

  const handleSort = column => {
    ReactGA.event({
      category: 'ReportsSearch',
      action: 'SearchSortTable',
      label: column,
    });
    if (column === sortBy) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(column);
      setSortDirection('asc');
    }
  };

  // Handle sort.
  displayedReports = [...displayedReports].sort((a, b) => {
    if (sortBy) {
      const aComp = a[sortBy] ? a[sortBy].toString() : "";
      const bComp = b[sortBy] ? b[sortBy].toString() : "";
      if (sortDirection === 'asc') {
        return aComp.localeCompare(bComp);
      } else {
        return bComp.localeCompare(aComp);
      }
    } else {
      return 0;
    }
  });

  return (
    <div className="table-container">
      <table>
        <colgroup>
        <col style={{ width: '30%' }} />
        <col style={{ width: '15%' }} />
        <col style={{ width: '15%' }} />
        <col style={{ width: '35%' }} />
        <col />
        </colgroup>
        <thead>
          <tr>
            <th onClick={() => handleSort('name')}>
              חברה <FaSort />
            </th>
            <th onClick={() => handleSort('category')}>
              קטגוריה <FaSort />
            </th>
            <th onClick={() => handleSort('year')}>
              שנה <FaSort />
            </th>
            <th onClick={() => handleSort('mainFindings')}>
              ממצאים כפי שהוצגו בדו"ח <FaSort />
            </th>
            <th>קישור לדו"ח המלא</th>
          </tr>
        </thead>
        <tbody>
          {displayedReports.map((report, index) => (
            <tr key={report.fullName}>
              <td>{report.name}</td>
              <td>{report.category}</td>
              <td>{report.year}</td>
              <td>
                <span>{renderText(report.mainFindings ? report.mainFindings : '', index)}</span>
                {report.mainFindings && report.mainFindings.length > 100 && (
                  <FontAwesomeIcon
                    icon={expandedRows[index] ? faChevronUp : faChevronDown}
                    className="toggle-icon"
                    onClick={() => toggleRow(index)}
                  />
                )}
              </td>
              <td>
                <a href={report.reportUrl}
                   target="_blank" rel="noopener noreferrer"
                   onClick={() => logOpenReport(report.fullName)}>
                  דו"ח
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const SearchableReportsTable = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedYear, setSelectedYear] = useState(DEFAULT_VALUE);
  const [selectedCategory, setSelectedCategory] = useState(DEFAULT_VALUE);

  const { reportsList } = useReportList();

  const handleYearChange = event => {
    ReactGA.event({
      category: 'ReportsSearch',
      action: 'SearchYearFilter',
      label: event.target.value,
    });
    setSelectedYear(event.target.value);
  };

  const handleCategoryChange = event => {
    ReactGA.event({
      category: 'ReportsSearch',
      action: 'SearchCategoryFilter',
      label: event.target.value,
    });
    setSelectedCategory(event.target.value);
  };

  const availableYears = [...new Set(reportsList.map(report => report.year))];
  const availableCategories = [...new Set(reportsList.map(report => report.category))];

  return (
    <div className="reports-table-content">
      <p>
        הגעת למאגר דוחות פערי שכר מגדריים בישראל.
        <br/>
        למה המאגר נבנה? כדי שהדוחות לא ילכו לאיבוד ברחבי הרשת - יש פה מידע חשוב על פערי השכר בארץ, וכדאי שנתייחס אליו.
        מצאת דוח חסר?&nbsp;
        <a href="#contact"
           className="link"
           onClick={() => navigate("contact")}>
           צרו קשר
        </a>.
      </p>
      <Box sx={{ flexGrow: 1, marginBottom: '15px', marginTop: '10px' }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              label="חפש.י חברה"
              variant="outlined"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              size="small"
              InputLabelProps={{ shrink: true }}
              fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6} sm={3} md={2}>
              <FormControl variant="outlined" size="small" fullWidth>
                <InputLabel id="year-select-label">שנה</InputLabel>
                <Select
                  labelId="year-select-label"
                  value={selectedYear}
                  onChange={handleYearChange}
                  label="Year">
                  <MenuItem value={DEFAULT_VALUE}>
                    {DEFAULT_VALUE}
                  </MenuItem>
                  {availableYears.map(year => (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} sm={3} md={2}>
              <FormControl variant="outlined" size="small" fullWidth>
                <InputLabel id="category-select-label">קטגוריה</InputLabel>
                <Select
                  labelId="category-select-label"
                  value={selectedCategory}
                  onChange={handleCategoryChange}
                  label="Category">
                  <MenuItem value={DEFAULT_VALUE}>
                    {DEFAULT_VALUE}
                  </MenuItem>
                  {availableCategories.map(category => (
                    <MenuItem key={category} value={category}>
                      {category}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
        <ReportsTable reports={reportsList}
                      searchTerm={searchTerm}
                      selectedYear={selectedYear}
                      selectedCategory={selectedCategory} />
    </div>
  );

};

export default SearchableReportsTable;
