export const R5 = {
    "category":"בנקים",
    "reports":[
        {
            "name":"הבינלאומי",
            "engName":"International Bank",
            "year":2022,
            "reportUrl":"https://www.fibi.co.il/media/myhdgkww/%D7%A9%D7%9B%D7%A8-%D7%A9%D7%95%D7%95%D7%94-%D7%9C%D7%A2%D7%95%D7%91%D7%93%D7%AA-%D7%95%D7%9C%D7%A2%D7%95%D7%91%D7%93-%D7%9C%D7%A9%D7%A0%D7%AA-2022-%D7%94%D7%91%D7%A0%D7%A7-%D7%94%D7%91%D7%99%D7%A0%D7%9C%D7%90%D7%95%D7%9E%D7%99.pdf",
            "originUrl":"https://www.fibi.co.il/wps/portal/FibiMenu/Marketing/Private/General/About/NoticeAndNews/WageDifferencesReport",
            "reportPath":"הבינלאומי_2022.pdf",
            "missing":false,
            "note":""
        },
        {
            "name":"בנק ישראל",
            "engName":"Bank of Israel",
            "year":2021,
            "reportUrl":"https://www.boi.org.il/media/l5unqrql/%D7%93%D7%95%D7%97-2021.pdf",
            "originUrl":"https://www.boi.org.il/publications/regularpublications/equal-wage/%D7%93%D7%95%D7%97-%D7%A9%D7%9B%D7%A8-%D7%A9%D7%95%D7%95%D7%94-%D7%9C%D7%A2%D7%95%D7%91%D7%93%D7%AA-%D7%95%D7%9C%D7%A2%D7%95%D7%91%D7%93-2021/",
            "reportPath":"בנק_ישראל_2021.pdf",
            "missing":false,
            "note":""
        },
        {
            "name":"בנק ישראל",
            "engName":"Bank of Israel",
            "year":2022,
            "reportUrl":"https://boi.org.il/media/m1qbxalk/%D7%93%D7%95%D7%97-%D7%A4%D7%95%D7%9E%D7%91%D7%99-%D7%A9%D7%9B%D7%A8-%D7%A9%D7%95%D7%95%D7%94-2022.pdf",
            "originUrl":"https://boi.org.il/publications/regularpublications/equal-wage/%D7%93%D7%95%D7%97-%D7%A9%D7%9B%D7%A8-%D7%A9%D7%95%D7%95%D7%94-%D7%9C%D7%A2%D7%95%D7%91%D7%93%D7%AA-%D7%95%D7%9C%D7%A2%D7%95%D7%91%D7%93-2022-1/#:~:text=%D7%A9%D7%99%D7%AA%D7%95%D7%A3%3A,%D7%91%D7%99%D7%9F%20%D7%92%D7%91%D7%A8%D7%99%D7%9D%20%D7%9C%D7%A0%D7%A9%D7%99%D7%9D%20%D7%91%D7%9B%D7%9C%20%D7%A7%D7%91%D7%95%D7%A6%D7%94.",
            "reportPath":"בנק_ישראל_2022.pdf",
            "missing":false,
            "note":""
        },
        {
            "name":"בנק הפועלים",
            "engName":"Hapoalim Bank",
            "year":2021,
            "reportUrl":"https://www.bankhapoalim.co.il/sites/bnhpcom/files/media/PDFS/Equal_salary_report_accessed_01062022.pdf",
            "originUrl":"",
            "reportPath":"בנק_הפועלים_2021.pdf",
            "missing":false,
            "note":"",
	          "mainFindings":"הבנק מעסיק בשורותיו מספר רב יותר של נשים מאשר גברים. נכון לסוף שנת ,2021 55.4% מהדרג הניהולי בבנק הן נשים. בבחינת רמת השכר הקובע לפיצויי פיטורין, אשר משקפת בצורה הנאמנה ביותר מבין 3 רמות השכר, את מידת השוויון בנתוני השכר של עובדות ועובדי הבנק, נמצא כי בהתייחס לכלל אוכלוסיית הבנק, שכר הגברים בבנק גבוה בכ- 3% משכר הנשים בבנק."
        },
        {
            "name":"בנק הפועלים",
            "engName":"Hapoalim Bank",
            "year":2022,
            "reportUrl":"https://www.bankhapoalim.co.il/sites/default/files/media/PDFS/public_report_29052023.pdf",
            "originUrl":"",
            "reportPath":"בנק_הפועלים_2022.pdf",
            "missing":false,
            "note":"",
	          "mainFindings":"הבנק מעסיק בשורותיו מספר רב יותר של נשים מאשר גברים. עובדות הבנק נמצאות בכל צומת ובכל תפקיד. 65% מעובדי הבנק הן נשים, 56% מכלל הדרג הניהולי בבנק הן נשים ו- 49% מסניפי הבנק מנוהלים ע''י נשים. בבחינת השכר הקובע לפיצויי פיטורין, אשר משקפת בצורה הנאמנה ביותר מבין 3 רמות השכר, את מידת השויון בנתוני השכר של עובדות ועובדי הבנק, נמצא כי בהתייחס לכלל אוכלוסיית הבנק, שכר הגברים בבנק גבוה בכ-3% משכר הנשים בבנק."
        },
        {
            "name":"בנק לאומי",
            "engName":"Leumi Bank",
            "year":2021,
            "reportUrl":"https://www.leumi.co.il/leumi-hebrew/s3fs-public/2023-07/dochLaoved2021.pdf",
            "originUrl":"https://www.leumi.co.il/Lobby/PayrollReport/49983/",
            "reportPath":"בנק_לאומי_2021.pdf",
            "missing":false,
            "note":"",
	          "mainFindings":"נכון לסוף 2021 נשים היוו כ- 60% מתוך כ- 8,000 העובדים בלאומי – בכל חטיבות הבנק. נשים היוו כ-54% משדרת ההנהלה הבכירה וכ-47% מתפקידי הניהול בסניפים. הניתוח מעלה כי ברוב הקבוצות פערי השכר לטובת גברים הם באחוזים בודדים, כאשר בחלק מהקבוצות ישנו פער לטובת נשים."
        },
        {
            "name":"בנק לאומי",
            "engName":"Leumi Bank",
            "year":2022,
            "reportUrl":"https://www.leumi.co.il/leumi-hebrew/s3fs-public/2023-07/dochLaoved2022.pdf",
            "originUrl":"https://www.leumi.co.il/Lobby/PayrollReport/49983/",
            "reportPath":"בנק_לאומי_2022.pdf",
            "missing":false,
            "note":"",
	          "mainFindings":"נכון לסוף 2022 נשים היוו מעל לכ-61% מתוך כ- 8,100 העובדים בלאומי – בכל חטיבות הבנק. נשים היוו כ-53% משדרת ההנהלה הבכירה וכ-45% מתפקידי הניהול בסניפים. הניתוח מעלה כי ברוב הקבוצות פערי השכר לטובת גברים הם באחוזים בודדים, כאשר בחלק מהקבוצות ישנו פער לטובת נשים. כמו כן, 45% מתוך 20% בעלי השכר הגבוה בבנק הן נשים."
        },
        {
            "name":"בנק דיסקונט",
            "engName":"Discount Bank",
            "year":2021,
            "reportUrl":"https://www.discountbank.co.il/DB/sites/marketing.discountbank.co.il/files/CMS%20media/Personal_Banking/Documents/press_releases/equal_pay_law_Discountbank.pdf",
            "originUrl":"",
            "reportPath":"בנק_דיסקונט_2021.pdf",
            "missing":false,
            "note":"",
			"mainFindings":"הממוצע המשוקלל של פער השכר בכל הקבוצות עומד על 3.25% לטובת הגברים 83% מהעובדות והעובדים נמצאים בקבוצות שפער השכר נמוך מ-10%."
        },
        {
            "name":"בנק דיסקונט",
            "engName":"Discount Bank",
            "year":2022,
            "reportUrl":"https://www.discountbank.co.il/DB/sites/marketing.discountbank.co.il/files/CMS%20media/Personal_Banking/Documents/press_releases/2023/equal_pay_law_Discountbank_2022.pdf",
            "originUrl":"",
            "reportPath":"בנק_דיסקונט_2022.pdf",
            "missing":false,
            "note":"",
	          "mainFindings":"58% מפורום ההנהלה המורחב של הבנק הינן נשים, נתון שאנו מאוד גאים בו. הממוצע המשוקלל של פער השכר בכל הקבוצות עומד על 4.14% לטובת הגברים. 91% מהעובדות והעובדים נמצאים בקבוצות שפער השכר נמוך מ-10%."
        },
        {
            "name":"בנק מרכנתיל",
            "engName":"Mercantile Bank",
            "year":2021,
            "reportUrl":"https://www.mercantile.co.il/MB/sites/mercantile.co.il/files/mercantile/PDF/equal_salary.pdf",
            "originUrl":"",
            "reportPath":"בנק_מרכנתיל_2021.pdf",
            "missing":false,
            "note":""
        },
        {
            "name":"בנק מרכנתיל",
            "engName":"Mercantile Bank",
            "year":2022,
            "reportUrl":"https://www.mercantile.co.il/MB/sites/mercantile.co.il/files/mercantile/PDF/equal_salary_2022.pdf",
            "originUrl":"",
            "reportPath":"בנק_מרכנתיל_2022.pdf",
            "missing":false,
            "note":""
        },
        {
            "name":"בנק יהב",
            "engName":"Yahav Bank",
            "year":2021,
            "reportUrl":"https://www.bank-yahav.co.il/media/qdkls3bb/equal-pay-report-2021.pdf",
            "originUrl":"https://www.bank-yahav.co.il/reports/",
            "reportPath":"בנק_יהב_2021.pdf",
            "missing":false,
            "note":""
        },
        {
            "name":"בנק יהב",
            "engName":"Yahav Bank",
            "year":2022,
            "reportUrl":"https://www.bank-yahav.co.il/media/flinjgug/equal-pay-report-2022.pdf",
            "originUrl":"https://www.bank-yahav.co.il/reports/",
            "reportPath":"בנק_יהב_2022.pdf",
            "missing":false,
            "note":""
        },
        {
            "name":"בנק מזרחי-טפחות",
            "engName":"Mizrahi-tefahot Bank",
            "year":2022,
            "reportUrl":"https://www.mizrahi-tefahot.co.il/mizmedia/11041/h7600769_v3.pdf",
            "originUrl":"",
            "reportPath":"בנק_מזרחי-טפחות_2022.pdf",
            "missing":false,
            "note":"",
	          "mainFindings":"גאים בכך שבשנת 2022 כ-97% מהמנהלים שהתמנו קודמו מקרב עובדי הבנק. כמו כן ומתוכם 60% היו נשים."
        }
    ]
};
