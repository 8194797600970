export const R7 = {
    "category":"ליסינג ורכב",
    "reports":[
        {
            "name":"אביס",
            "engName":"Avis",
            "year":2022,
            "reportUrl":"https://www.avis.co.il/media/m5vnx30h/%D7%A4%D7%95%D7%9E%D7%91%D7%99-%D7%A1%D7%95%D7%A4%D7%99-2022-avis-%D7%9E%D7%95%D7%A0%D7%92%D7%A9.pdf",
            "originUrl":"",
            "reportPath":"אביס_2022.pdf",
            "missing":false,
            "note":""
        },
        {
            "name":"אלדן",
            "engName":"Eldan",
            "year":2021,
            "reportUrl":"https://www.eldan.co.il/wage",
            "originUrl":"",
            "reportPath":"אלדן_2021.pdf",
            "missing":false,
            "note":""
        },
        {
            "name":"יוניברסל מוטורס",
            "engName":"umigroup",
            "year":2022,
            "reportUrl":"https://www.umigroup.co.il/src/contents/d4af86f0080844d1a9e3db62e322a22a__UMI_2022_.pdf",
            "originUrl":"",
            "reportPath":"",
            "missing":true,
            "note":"broken"
        },
        {
            "name":"כלמוביל",
            "engName":"colmobil",
            "year":2021,
            "reportUrl":"https://www.colmobil.co.il/wp-content/uploads/%D7%93%D7%99%D7%95%D7%95%D7%97-%D7%A4%D7%95%D7%9E%D7%91%D7%99-%D7%A4%D7%A2%D7%A8%D7%99-%D7%A9%D7%9B%D7%A8-%D7%9E%D7%92%D7%93%D7%A8%D7%99%D7%99%D7%9D-2021-310522.pdf",
            "originUrl":"",
            "reportPath":"",
            "missing":true,
            "note":"broken"
        },
        {
            "name":"כלמוביל",
            "engName":"colmobil",
            "year":2022,
            "reportUrl":"https://www.colmobil.co.il/wp-content/uploads/%D7%93%D7%99%D7%95%D7%95%D7%97-%D7%A4%D7%95%D7%9E%D7%91%D7%99-%D7%A4%D7%A2%D7%A8%D7%99-%D7%A9%D7%9B%D7%A8-%D7%9E%D7%92%D7%93%D7%A8%D7%99%D7%99%D7%9D-2022.pdf",
            "originUrl":"",
            "reportPath":"",
            "missing":true,
            "note":"broken"
        },
        {
            "name":"קרסו מוטורס",
            "engName":"carasso",
            "year":2021,
            "reportUrl":"https://www-europe.nissan-cdn.net/content/dam/Nissan/israel/legal/carasso-equal-pay-report-2021.pdf",
            "originUrl":"",
            "reportPath":"קרסו_מוטורס_2021.pdf",
            "missing":false,
            "note":""
        },
        {
            "name":"אלבר",
            "engName":"albar",
            "year":2022,
            "reportUrl":"https://www.albar.co.il/%D7%93%D7%95%D7%97-%D7%A9%D7%9B%D7%A8-%D7%A9%D7%95%D7%95%D7%94/",
            "originUrl":"",
            "reportPath":"אלבר_2022.pdf",
            "missing":false,
            "note":""
        },
        {
            "name":"שלמה Sixt",
            "engName":"shlomo",
            "year":2022,
            "reportUrl":"https://www.shlomo.co.il/%D7%97%D7%95%D7%A7_%D7%A9%D7%9B%D7%A8_%D7%A9%D7%95%D7%95%D7%94_%D7%9C%D7%A2%D7%95%D7%91%D7%93%D7%AA_%D7%95%D7%9C%D7%A2%D7%95%D7%91%D7%93",
            "originUrl":"",
            "reportPath":"שלמה_Sixt_2022.pdf",
            "missing":false,
            "note":"",
			"mainFindings":"בחברת ש. שלמה החזקות בע''מ, שהינה תאגיד מדווח כמשמעות המונח בחוק ניירות ערך, התשכ''ח-1968, קיים עובד אחד. נוכח האמור אין פערי שכר שניתן לדווח עליהם בהתאם לתיקון מס' 6  לחוק שכר שווה לעובדת ולעובד."
        }
    ]
};
