export const R8 = {
    "category":"מוצרי צריכה, מזון ומשקאות",
    "reports":[
        {
            "name":"יוניליוור",
            "engName":"unilever",
            "year":2021,
            "reportUrl":"https://www.unilever.co.il/news/2022/2021-gender-pay-report/",
            "originUrl":"",
            "reportPath":"",
            "missing":false,
            "note":"html"
        },
        {
            "name":"יוניליוור",
            "engName":"unilever",
            "year":2022,
            "reportUrl":"https://www.unilever.co.il/news/2023/2022-gender-pay-report/",
            "originUrl":"",
            "reportPath":"",
            "missing":false,
            "note":"html"
        },
        {
            "name":"אוסם נסטלה",
            "engName":"osem-nestle",
            "year":2021,
            "reportUrl":"https://www.osem-nestle.co.il/sites/site.prod.osem-nestle.co.il/files/2022-06/%D7%93%D7%95%D7%97%20%D7%A4%D7%A2%D7%A8%D7%99%20%D7%A9%D7%9B%D7%A8%202021_0.pdf",
            "originUrl":"https://www.osem-nestle.co.il/about/news/%D7%97%D7%95%D7%A7-%D7%A9%D7%9B%D7%A8-%D7%A9%D7%95%D7%95%D7%94-%D7%9C%D7%A2%D7%95%D7%91%D7%93%D7%AA-%D7%95%D7%9C%D7%A2%D7%95%D7%91%D7%93-%D7%93%D7%95%D7%97-2021",
            "reportPath":"אוסם_נסטלה_2021.pdf",
            "missing":false,
            "note":"",
			"mainFindings":"ב-15 מתוך 16 הקבוצות לא נמצאו פערי שכר משמעותיים בשכר הבסיס בין המגדרים (פערים הנמוכים מ-6%), בקבוצה אחת בלבד (קבוצה ז') ישנו פער של 12% הנובע מוותק. בשכר הברוטו למס הכנסה (הכולל בין היתר תשלום שעות נוספות ומשמרות) לא נמצאו פערי שכר משמעותיים ב-10 מתוך 16 הקבוצות, ב-6 קבוצות נמצאו פערים הגבוהים מ-6% הנובעים ממשמרות, שעות נוספות ושנות וותק, אך באופן מובהק אינם נובעים ממגדר."
        },
        {
            "name":"אוסם נסטלה",
            "engName":"osem-nestle",
            "year":2022,
            "reportUrl":"https://www.osem-nestle.co.il/sites/site.prod.osem-nestle.co.il/files/2023-06/%D7%93%D7%95%D7%97%20%D7%A9%D7%9B%D7%A8%20%D7%A9%D7%95%D7%95%D7%94.pdf",
            "originUrl":"https://www.osem-nestle.co.il/about/news/%D7%97%D7%95%D7%A7-%D7%A9%D7%9B%D7%A8-%D7%A9%D7%95%D7%95%D7%94-%D7%9C%D7%A2%D7%95%D7%91%D7%93%D7%AA-%D7%95%D7%9C%D7%A2%D7%95%D7%91%D7%93-%D7%93%D7%95%D7%97-2022",
            "reportPath":"אוסם_נסטלה_2022.pdf",
            "missing":false,
            "note":""
        },
        {
            "name":"שטראוס",
            "engName":"strauss",
            "year":2021,
            "reportUrl":"https://www.strauss-group.co.il/wp-content/blogs.dir/1/files/%D7%93%D7%99%D7%95%D7%95%D7%97-%D7%A4%D7%95%D7%9E%D7%91%D7%99-%D7%97%D7%95%D7%A7-%D7%A9%D7%9B%D7%A8-%D7%A9%D7%95%D7%95%D7%94-%D7%9E%D7%95%D7%A0%D7%92%D7%A9-%D7%A2%D7%9D-%D7%90%D7%99%D7%99%D7%A7%D7%95%D7%9F.pdf",
            "originUrl":"",
            "reportPath":"שטראוס_2021.pdf",
            "missing":false,
            "note":"",
			"mainFindings":"ברוב הקבוצות (10 מתוך 17), מצאנו פערים של אחוזים בודדים (בין 1%-5% בלבד) מחציתם לטובת הגברים ומחציתם לטובת הנשים. ביתר הקבוצות, בהן נמצאו פערים שעולים על 5% לטובת הגברים, בצענו ניתוח מעמיק וגילינו שרובם נובעים מסיבות כמו וותק, ניסיון מקצועי, ביצוע משמרות, שעות נוספות, תשלומי נסיעות המבוססים על מקום מגורים וכדומה."
        },
        {
            "name":"שטראוס",
            "engName":"strauss",
            "year":2022,
            "reportUrl":"https://www.strauss-group.co.il/wp-content/blogs.dir/1/files/%D7%93%D7%95%D7%97-%D7%A4%D7%95%D7%9E%D7%91%D7%99-1.6.2023.pdf",
            "originUrl":"https://www.strauss-group.co.il/sustainability/employee-engagement/%D7%93%D7%99%D7%95%D7%95%D7%97-%D7%A4%D7%95%D7%9E%D7%91%D7%99-%D7%9C%D7%A9%D7%A0%D7%AA-2022-%D7%97%D7%95%D7%A7-%D7%A9%D7%9B%D7%A8-%D7%A9%D7%95%D7%95%D7%94-%D7%9C%D7%A2%D7%95%D7%91%D7%93/",
            "reportPath":"שטראוס_2022.pdf",
            "missing":false,
            "note":""
        },
        {
            "name":"מאפיית אנג'ל",
            "engName":"Angel",
            "year":2022,
            "reportUrl":"https://www.angel.co.il/sysvault/docsfiles2/cddto-file-142989133.pdf",
            "originUrl":"https://www.angel.co.il/financial/report-133",
            "reportPath":"מאפיית_אנגל_2022.pdf",
            "missing":false,
            "note":""
        },
        {
            "name":"רמי לוי",
            "engName":"rami-levy",
            "year":2021,
            "reportUrl":"https://img.rami-levy.co.il/public/static/pumbi01062022.pdf",
            "originUrl":"",
            "reportPath":"רמי_לוי_2021.pdf",
            "missing":false,
            "note":"",
			"mainFindings":"פילחנו בדוח שלהלן את העובדות והעובדים ל-21 קבוצות בעלות מאפיינים דומים של תפקידים. ב-16 קבוצות נמצאו פערים של אחוזים בודדים (עד 5%), כאשר מתוכם קבוצות עם פערים לטובת הנשים."
        },
        {
            "name":"רמי לוי",
            "engName":"rami-levy",
            "year":2022,
            "reportUrl":"https://img.rami-levy.co.il/public/static/99c522ac-d259-404e-94a7-1502c1ee327f.pdf",
            "originUrl":"",
            "reportPath":"רמי_לוי_2022.pdf",
            "missing":false,
            "note":""
        },
        {
            "name":"יוחננוף",
            "engName":"yochananof",
            "year":2022,
            "reportUrl":"https://yochananof.co.il/media/catalog/category/corporate/%D7%93%D7%95%D7%97-%D7%A9%D7%9B%D7%A8-%D7%A9%D7%95%D7%95%D7%94-%D7%A4%D7%95%D7%9E%D7%91%D7%99-2022.pdf",
            "originUrl":"",
            "reportPath":"יוחננוף_2022.pdf",
            "missing":false,
            "note":""
        },
        {
            "name":"שופרסל",
            "engName":"Shufersal",
            "year":2021,
            "reportUrl":"https://www.shufersal.co.il/umbracomedia/3682/%D7%93%D7%95%D7%97-%D7%A4%D7%95%D7%9E%D7%91%D7%99-2021.pdf",
            "originUrl":"https://www.funder.co.il/article/134851",
            "reportPath":"שופרסל_2021.pdf",
            "missing":false,
            "note":""
        },
        {
            "name":"שופרסל",
            "engName":"Shufersal",
            "year":2022,
            "reportUrl":"https://www.shufersal.co.il/umbracomedia/5696/%D7%93%D7%95%D7%97-%D7%A4%D7%95%D7%9E%D7%91%D7%99-2022.pdf",
            "originUrl":"",
            "reportPath":"שופרסל_2022.pdf",
            "missing":false,
            "note":""
        },
        {
            "name":"תנובה",
            "engName":"Tnuva",
            "year":2022,
            "reportUrl":"https://www.tnuva.co.il/uploads/f_6478610d37b6a_1685610765.pdf",
            "originUrl":"https://www.tnuva.co.il/about/business-info/messages/%D7%93%D7%95%D7%97%20%D7%A9%D7%9B%D7%A8%20%D7%A9%D7%95%D7%95%D7%94%202022/",
            "reportPath":"",
            "missing":true,
            "note":"broken"
        },
        {
            "name":"מויד מקבוצת תנובה",
            "engName":"Tnuva",
            "year":2022,
            "reportUrl":"https://www.tnuva.co.il/uploads/f_6478620570438_1685611013.pdf",
            "originUrl":"https://www.tnuva.co.il/about/business-info/messages/%D7%93%D7%95%D7%97%20%D7%A9%D7%9B%D7%A8%20%D7%A9%D7%95%D7%95%D7%94%202022/",
            "reportPath":"",
            "missing":true,
            "note":"broken"
        },
        {
            "name":"דיפלומט החזקות",
            "engName":"Diplomat",
            "year":2022,
            "reportUrl":"https://www.diplomat-global.com/wp-content/uploads/2022/06/%D7%97%D7%95%D7%A7-%D7%A9%D7%9B%D7%A8-%D7%A9%D7%95%D7%95%D7%94-%D7%93%D7%95%D7%97-%D7%A6%D7%99%D7%91%D7%95%D7%A8%D7%99-3.pdf",
            "originUrl":"",
            "reportPath":"דיפלומט_החזקות_2022.pdf",
            "missing":false,
            "note":"",
			"mainFindings":"ערכי החברה והיחס השווה לגברים ונשים באים לידי ביטוי גם בשיעור הנשים בקרב ההנהלה הבכירה העומד על 42%. בהסתכלות על שכר ברוטו למס, המנורמל למשרה מלאה, מצאנו כי בשליש מהתפקידים ישנו פער חיובי לטובת הנשים, כאשר הגבוה ביותר עומד על 8%. בשליש מהתפקידים גברים מרוויחים בממוצע יותר מאשר נשים, כאשר הפער הגבוה ביותר עומד על 7%. ובשליש מהתפקידים לא נמצא פער בין גברים ונשים."
        }
    ]
};
