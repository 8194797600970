
import ReactGA from 'react-ga4';

export const navigate = tab => {
  ReactGA.event({
    category: 'Navigation',
    action: 'TabSwitchFromLink',
    label: tab,
  });
  document.getElementsByClassName(tab)[0].click()
};

export const logOpenReport = reportName => {
  ReactGA.event({
    category: 'OpenReport',
    action: 'OpenReport',
    label: reportName,
  });
};
