import React from 'react';
import './Answer.css';


const A2 = () => {
  return (
    <div className="answer">
      <p>
        כחלק מחוק שכר שווה, ב 25.08.2020 התקבל&nbsp;
        <a href='https://www.gov.il/BlobFolder/generalpage/employers-publish-pay-gap/he/shivion_equal-opportunities-at-work_fix6-instructions230423.pdf'
           className="link"
           target='_blank' rel="noopener noreferrer">תיקון מס' 6 לחוק</a>
          - חובת מעסיקים לפרסם מידע בדבר פערי השכר בין גברים לנשים.
        <br />
        התיקון מחייב כל גוף (פרטי וציבורי) שמעסיק למעלה מ- 518 עובדים,
        וכל גוף ציבורי אשר חלה עליו מלכתחילה חובה לפרסם מידע (ללא קשר למספר העובדים),
        להפיק מדי שנה 2 דוחות (דוח פנימי ודוח פומבי), המפרטים את פערי השכר במקום העבודה בין גברים לנשים וכן למסור הודעה לעובד.ת בדבר פערי השכר.
        <br />
        המטרה - להגביר את השקיפות ולהביא לצמצום פערי השכר בשוק העבודה. למידע נוסף, לחצו&nbsp;
        <a href='https://www.kolzchut.org.il/he/%D7%97%D7%95%D7%91%D7%AA_%D7%9E%D7%A2%D7%A1%D7%99%D7%A7%D7%99%D7%9D_%D7%9C%D7%A4%D7%A8%D7%A1%D7%9D_%D7%9E%D7%99%D7%93%D7%A2_%D7%91%D7%93%D7%91%D7%A8_%D7%A4%D7%A2%D7%A8%D7%99_%D7%94%D7%A9%D7%9B%D7%A8_%D7%91%D7%99%D7%9F_%D7%92%D7%91%D7%A8%D7%99%D7%9D_%D7%9C%D7%A0%D7%A9%D7%99%D7%9D'
           className="link"
           target='_blank' rel="noopener noreferrer">כאן.</a>
      </p>
    </div>
  )
}

export default A2;
