export const R9 = {
    "category":"בנייה ונדלן",
    "reports":[
        {
            "name":"קבוצת עזריאלי",
            "engName":"Azrieli",
            "year":2021,
            "reportUrl":"https://www.azrieligroup.com/wp-content/uploads/2022/05/%D7%93%D7%95%D7%97-%D7%A4%D7%95%D7%9E%D7%91%D7%99-%D7%97%D7%95%D7%A7-%D7%A9%D7%9B%D7%A8-%D7%A9%D7%95%D7%95%D7%94.pdf",
            "originUrl":"",
            "reportPath":"קבוצת_עזריאלי_2021.pdf",
            "missing":false,
            "note":""
        },
        {
            "name":"קבוצת עזריאלי",
            "engName":"Azrieli",
            "year":2022,
            "reportUrl":"https://www.azrieligroup.com/wp-content/uploads/2023/06/%D7%93%D7%95%D7%97-%D7%A4%D7%95%D7%9E%D7%91%D7%99-%D7%97%D7%95%D7%A7-%D7%A9%D7%9B%D7%A8-%D7%A9%D7%95%D7%95%D7%94-2022.pdf",
            "originUrl":"",
            "reportPath":"קבוצת_עזריאלי_2022.pdf",
            "missing":false,
            "note":""
        },
        {
            "name":"שיכון ובינוי",
            "engName":"shikunbinui",
            "year":2022,
            "reportUrl":"https://shikunbinui.com/he/company-policies",
            "originUrl":"",
            "reportPath":"",
            "missing":false,
            "note":"html",
			"mainFindings":"קטגוריה ראשונה מציגה את הפער בין שכר הנשים לשכר הגברים על פי השכר הקובע לפיצויי פיטורים. בקטגוריה זו נמצא כי הפער נוטה לטובת הנשים – וב-6 מתוך 7 קבוצות שכרן של הנשים גבוה יותר מזה של הגברים. בקבוצה אחת בלבד נמצא שהפער נוטה לטובת הגברים, אך פער זה מסתכם ב-1% בלבד ולפיכך איננו משמעותי. קטגוריה שנייה מציגה את הפער בין שכר הנשים לשכר הגברים על פי השכר הכולל לחישוב מס. בקטגוריה זו נכללות גם תוספות תלויות תפקיד – ומדובר בתוספות שעיקרן רלוונטי לתפקידי השטח המבוצעים באתרי הבנייה (הכוונה לתוספות שאינן מובאות בחשבון בבסיס השכר לפיצויי פיטורים). לנוכח זאת, ובשים לב לכך שמרבית העובדים באתרי הבנייה משתייכים למגדר הגברי – הרי שב-3 מתוך 7 הקבוצות בקטגוריה זו – הפער בשכר נוטה לטובת הגברים. בשתי קבוצות נוספות שכמעט ולא כוללות בתוכן עובדים באתרים הבנייה – ממשיך להישמר הפער לטובת הנשים, ובקבוצה אחת הפער הוא מינורי ומסתכם ב- 2% בלבד (לטובת הגברים). קטגוריה שלישית מציגה את הפער בין שכר הנשים לשכר הגברים על פי השכר הכולל חישוב מס, לרבות הפקדות מעסיק לביטוח פנסיוני. האמור לעיל ביחס לקטגוריה השנייה נכון בהתאמה גם ביחס לקטגוריה זו, תוך שיובהר כי הפער המינורי בקבוצה האחת מצטמצם מ-2% לעיל ל-1% בלבד."
        },
        {
            "name":"קבוצת אשטרום",
            "engName":"ashtrom",
            "year":2021,
            "reportUrl":"https://ir.ashtrom.co.il/wp-content/uploads/2022/06/%D7%A7%D7%91%D7%95%D7%A6%D7%AA-%D7%90%D7%A9%D7%98%D7%A8%D7%95%D7%9D.pdf",
            "originUrl":"",
            "reportPath":"קבוצת_אשטרום_2021.pdf",
            "missing":false,
            "note":""
        },
        {
            "name":"קבוצת אשטרום",
            "engName":"ashtrom",
            "year":2022,
            "reportUrl":"https://ir.ashtrom.co.il/wp-content/uploads/2023/06/%D7%A7%D7%91%D7%95%D7%A6%D7%AA-%D7%90%D7%A9%D7%98%D7%A8%D7%95%D7%9D-%D7%91%D7%A2%D7%9E.pdf",
            "originUrl":"",
            "reportPath":"קבוצת_אשטרום_2022.pdf",
            "missing":false,
            "note":""
        },
        {
            "name":"שפיר הנדסה",
            "engName":"shapir",
            "year":2021,
            "reportUrl":"https://www.shapir.co.il/wp-content/uploads/2023/06/%D7%A9%D7%A4%D7%99%D7%A8-%D7%94%D7%A0%D7%93%D7%A1%D7%94-%D7%97%D7%95%D7%A7-%D7%A9%D7%9B%D7%A8-%D7%A9%D7%95%D7%95%D7%94-%D7%9C%D7%A2%D7%95%D7%91%D7%93%D7%AA-%D7%95%D7%9C%D7%A2%D7%95%D7%91%D7%93.pdf",
            "originUrl":"",
            "reportPath":"שפיר_הנדסה_2021.pdf",
            "missing":false,
            "note":""
        },
        {
            "name":"מליסרון",
            "engName":"melisron",
            "year":2021,
            "reportUrl":"https://www.melisron.co.il/wp-content/uploads/2022/06/Public-Report-Commissionaire-Designed-VersionS1.pdf",
            "originUrl":"",
            "reportPath":"מליסרון_2021.pdf",
            "missing":false,
            "note":""
        },
        {
            "name":"מליסרון",
            "engName":"melisron",
            "year":2022,
            "reportUrl":"https://www.melisron.co.il/wp-content/uploads/2023/06/Public-Report-Commissionaire-Designed-Version-Final-04.06.23.pdf",
            "originUrl":"",
            "reportPath":"מליסרון_2022.pdf",
            "missing":false,
            "note":""
        },
        {
            "name":"מבנה",
            "engName":"mivnegroup",
            "year":2021,
            "reportUrl":"https://www.mivnegroup.co.il/uploads/capitalMarketsFiles/2022/08/49-file-32F8fivm.pdf",
            "originUrl":"",
            "reportPath":"מבנה_2021.pdf",
            "missing":false,
            "note":""
        },
        {
            "name":"מבנה",
            "engName":"mivnegroup",
            "year":2022,
            "reportUrl":"https://www.mivnegroup.co.il/uploads/capitalMarketsFiles/2023/06/61-file-8ppm0RiP.pdf",
            "originUrl":"",
            "reportPath":"מבנה_2022.pdf",
            "missing":false,
            "note":""
        },
        {
            "name":"אמות",
            "engName":"amot",
            "year":2021,
            "reportUrl":"https://prodsitestorageaccount.blob.core.windows.net/public/82c54e0b-60ed-448a-9ce9-f2e3ef73f000-%d7%93%d7%95%d7%97_%d7%a9%d7%9b%d7%a8_%d7%a9%d7%95%d7%95%d7%94_%d7%9c%d7%a2%d7%95%d7%91%d7%93%d7%aa_%d7%95%d7%9c%d7%a2%d7%95%d7%91%d7%93_(1.6.22).pdf",
            "originUrl":"",
            "reportPath":"אמות_2021.pdf",
            "missing":false,
            "note":""
        },
        {
            "name":"אמות",
            "engName":"amot",
            "year":2022,
            "reportUrl":"https://prodsitestorageaccount.blob.core.windows.net/public/6c3a78bb-64b9-44ab-ae95-6d2ab59ecfb3-%d7%93%d7%95%d7%97_%d7%a9%d7%9b%d7%a8_%d7%a9%d7%95%d7%95%d7%94_2022_2.pdf",
            "originUrl":"",
            "reportPath":"אמות_2022.pdf",
            "missing":false,
            "note":""
        },
        {
            "name":"הכשרת הישוב",
            "engName":"ildc",
            "year":2021,
            "reportUrl":"https://www.ildc.co.il/editor/assets/2021_report.pdf",
            "originUrl":"",
            "reportPath":"הכשרת_הישוב_2021.pdf",
            "missing":false,
            "note":""
        },
        {
            "name":"אינרום",
            "engName":"inrom",
            "year":2021,
            "reportUrl":"https://inrom-group.co.il/%D7%A7%D7%A9%D7%A8%D7%99-%D7%9E%D7%A9%D7%A7%D7%99%D7%A2%D7%99%D7%9D/",
            "originUrl":"",
            "reportPath":"",
            "missing":true,
            "note":"broken"
        },
        {
            "name":"אשטרום נכסים",
            "engName":"ashtrom-properties",
            "year":2021,
            "reportUrl":"https://ir.ashtrom-properties.co.il/wp-content/uploads/2022/06/%D7%90%D7%A9%D7%98%D7%A8%D7%95%D7%9D-%D7%A0%D7%9B%D7%A1%D7%99%D7%9D.pdf",
            "originUrl":"",
            "reportPath":"אשטרום_נכסים_2021.pdf",
            "missing":false,
            "note":""
        },
        {
            "name":"אשטרום נכסים",
            "engName":"ashtrom-properties",
            "year":2022,
            "reportUrl":"https://ir.ashtrom-properties.co.il/wp-content/uploads/2023/06/%D7%90%D7%A9%D7%98%D7%A8%D7%95%D7%9D-%D7%A0%D7%9B%D7%A1%D7%99%D7%9D-%D7%91%D7%A2%D7%9E.pdf",
            "originUrl":"",
            "reportPath":"אשטרום_נכסים_2022.pdf",
            "missing":false,
            "note":""
        },
        {
            "name":"ישרס",
            "engName":"israsgroup",
            "year":2021,
            "reportUrl":"https://israsgroup.co.il/%D7%97%D7%95%D7%A7-%D7%A9%D7%99%D7%95%D7%95%D7%99%D7%9F-%D7%A9%D7%9B%D7%A8/",
            "originUrl":"",
            "reportPath":"ישרס_2021.pdf",
            "missing":false,
            "note":"",
			"mainFindings":"מניתוח פערי השכר בקבוצת הפילוח עולה כי למעט קבוצה אחת, פערי השכר בין גברים לנשים הינם עד 11% לכל היותר. בקבוצה אחת ספיציפית פער השכר בין גברים לנשים הינו 37% אשר נובע בעיקר בשל הבדל משמעותי בוותק ובניסיון של האוכלוסיה באותה קבוצה."
        },
        {
            "name":"אקרו",
            "engName":"acronadlan",
            "year":2022,
            "reportUrl":"https://acronadlan.com/wp-content/uploads/2023/06/amendment-law-equal-wage-to-employee-and-employee-acro-group-ltd.pdf",
            "originUrl":"",
            "reportPath":"",
            "missing":false,
            "note":"html"
        },
        {
            "name":"מגה אור",
            "engName":"megaor",
            "year":2021,
            "reportUrl":"https://megaor.co.il/elementor-5997/",
            "originUrl":"",
            "reportPath":"",
            "missing":false,
            "note":"html"
        },
        {
            "name":"מגה אור",
            "engName":"megaor",
            "year":2022,
            "reportUrl":"https://megaor.co.il/%D7%97%D7%95%D7%A7-%D7%A9%D7%9B%D7%A8-%D7%A9%D7%95%D7%95%D7%94-%D7%93%D7%95%D7%97-%D7%A4%D7%95%D7%9E%D7%91%D7%99-%D7%9C%D7%A9%D7%A0%D7%AA-2022/",
            "originUrl":"",
            "reportPath":"",
            "missing":false,
            "note":"html"
        },
        {
            "name":"ישראל קנדה",
            "engName":"israel-canada",
            "year":2022,
            "reportUrl":"https://israel-canada.co.il/equal-pay/",
            "originUrl":"",
            "reportPath":"ישראל_קנדה_2022.pdf",
            "missing":false,
            "note":""
        },
        {
            "name":"דניה סיבוס",
            "engName":"denya-group",
            "year":2021,
            "reportUrl":"https://www.denya-group.com/wp-content/uploads/2022/05/%D7%93%D7%95%D7%97-%D7%A4%D7%95%D7%9E%D7%91%D7%99-%D7%A9%D7%9B%D7%A8-%D7%A9%D7%95%D7%95%D7%94-%D7%9C%D7%A2%D7%95%D7%91%D7%93%D7%AA-%D7%95%D7%9C%D7%A2%D7%95%D7%91%D7%93-2021.pdf",
            "originUrl":"",
            "reportPath":"דניה_סיבוס_2021.pdf",
            "missing":false,
            "note":""
        },
        {
            "name":"חברת השקעות דיסקונט וחברה לנכסים ולבנין",
            "engName":"dic",
            "year":2021,
            "reportUrl":"https://www.dic.co.il/media/d1ijnx3m/%D7%93%D7%95%D7%97-%D7%91%D7%94%D7%AA%D7%90%D7%9D-%D7%9C%D7%97%D7%95%D7%A7-%D7%A9%D7%9B%D7%A8-%D7%A9%D7%95%D7%95%D7%94-%D7%9C%D7%A2%D7%95%D7%91%D7%93-%D7%95%D7%9C%D7%A2%D7%95%D7%91%D7%93%D7%AA.pdf",
            "originUrl":"",
            "reportPath":"חברת_השקעות_דיסקונט_וחברה_לנכסים_ולבנין_2021.pdf",
            "missing":false,
            "note":""
        },
        {
            "name":"גב-ים",
            "engName":"gav-yam",
            "year":2021,
            "reportUrl":"https://www.gav-yam.co.il/wp-content/uploads/2022/06/%D7%93%D7%95%D7%97-%D7%A9%D7%9B%D7%A8-%D7%A4%D7%95%D7%9E%D7%91%D7%99.pdf",
            "originUrl":"",
            "reportPath":"גב-ים_2021.pdf",
            "missing":false,
            "note":""
        },
        {
            "name":"גב-ים",
            "engName":"gav-yam",
            "year":2022,
            "reportUrl":"https://www.gav-yam.co.il/wp-content/uploads/2023/06/%D7%93%D7%95%D7%97-%D7%A9%D7%9B%D7%A8-%D7%A4%D7%95%D7%9E%D7%91%D7%99_2022.pdf",
            "originUrl":"",
            "reportPath":"גב-ים_2022.pdf",
            "missing":false,
            "note":""
        }
    ]
};
