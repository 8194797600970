export const R13 = {
    "category":"תיירות ותעופה",
    "reports":[
        {
            "name":"אל על",
            "engName":"elal",
            "year":2021,
            "reportUrl":"https://www.elal.com/SiteCollectionDocuments/REPORT-EQUAL-21.pdf",
            "originUrl":"",
            "reportPath":"אל_על_2021.pdf",
            "missing":false,
            "note":""
        },
        {
            "name":"ישראייר",
            "engName":"israir",
            "year":2022,
            "reportUrl":"https://www.israir.co.il/Info/Company_Announcements.html",
            "originUrl":"",
            "reportPath":"ישראייר_2022.pdf",
            "missing":false,
            "note":""
        },
        {
            "name":"קשרי תעופה",
            "engName":"kishrey-teufa",
            "year":2022,
            "reportUrl":"https://www.kishrey-teufa.co.il/about/shave.html",
            "originUrl":"",
            "reportPath":"קשרי_תעופה_2022.pdf",
            "missing":false,
            "note":"",
			"mainFindings":"פער השכר הממוצע בין העובדות לעובדים של כלל המועסקים בחברה עומד על כ1.3% לטובת העובדות."
        },
        {
            "name":"מלונות דן",
            "engName":"dan hotels",
            "year":2022,
            "reportUrl":"https://www.danhotels.co.il/AboutDanhotels/Equalpay",
            "originUrl":"",
            "reportPath":"מלונות_דן_2022.pdf",
            "missing":false,
            "note":""
        },
        {
            "name":"עידית מקבוצת מלונות דן",
            "engName":"idit",
            "year":2022,
            "reportUrl":"https://www.idit-dan.co.il/equal-pay",
            "originUrl":"",
            "reportPath":"עידית_מקבוצת_מלונות_דן_2022.pdf",
            "missing":false,
            "note":""
        }
    ]
};
