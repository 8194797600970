import React, { useState } from 'react';
import './AppTabs.css';

import ReactGA from 'react-ga4';

import About from './Tabs/About';
import AboutTheLaw from './Tabs/AboutTheLaw';
import Contact from './Tabs/Contact';
import SearchableReportsTable from './Tabs/SearchableReportsTable';
import logo from './assets/logo.png';

const AppTabs = () => {
  const getActiveTabName = () => {
    const tabName = window.location.hash !== "" ? window.location.hash.split("#")[1].toLocaleLowerCase() : "";
    return tabName !== "" ? tabName : "reports";
  }
  const [selectedTab, setSelectedTab] = useState(getActiveTabName()); // Default selected tab is About

  let tabContent;
  switch (selectedTab) {
    case "reports":
      tabContent = <SearchableReportsTable />;
      break;
    case "about":
      tabContent = <About />;
      break;
    case "thelaw":
        tabContent = <AboutTheLaw />;
        break;
    case "contact":
      tabContent = <Contact />;
      break;
    default:
      tabContent = <SearchableReportsTable />;
  }

  const isActiveTab = (tabName) => {
    return tabName.toLocaleLowerCase() === getActiveTabName();
  }
  const switchTabs = () => {
    setSelectedTab(getActiveTabName());
    ReactGA.event({
      category: 'Navigation',
      action: 'TabSwitch',
      label: getActiveTabName(),
    });
    window.scrollTo(0, 0);
  }
  window.addEventListener('hashchange', switchTabs);

  return (
    <div>
      <div className="header-container">
        <a href="#about">
          <img src={logo}
               className="header-image-container"
               onClick={() => {document.getElementsByClassName("about")[0].click()}}
               alt="" />
        </a>
        <header className="header">
          <h1 className="title">
            דוח שכר (לא) שווה לעובדת ולעובד
          </h1>
          <div className="title-line"></div>
        </header>
      </div>
      <nav className="tabs">
        <a href="#reports"
           className={`tab reports ${isActiveTab("reports")  ? "active" : ""}`}>
          צפייה בדו"חות
        </a>
        <span className="divider">|</span>
        <a href="#about"
           className={`tab about ${isActiveTab("about")  ? "active" : ""}`}>
          רקע
        </a>
        <span className="divider">|</span>
        <a href="#thelaw"
           className={`tab thelaw ${isActiveTab("thelaw")  ? "active" : ""}`}>
          על החוק
        </a>
        <span className="divider">|</span>
        <a href="#contact"
           className={`tab contact ${isActiveTab("contact")  ? "active" : ""}`}>
          צרו קשר
        </a>
      </nav>
      <div className="main-content">
        {tabContent}
      </div>
    </div>
  );
};

export default AppTabs;
