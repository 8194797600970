export const R12 = {
    "category":"תחבורה ציבורית",
    "reports":[
        {
            "name":"אגד",
            "engName":"egged",
            "year":2022,
            "reportUrl":"https://www.egged.co.il/%D7%9E%D7%90%D7%9E%D7%A8-38637,1450-%D7%93%D7%95%D7%97-%D7%A4%D7%95%D7%9E%D7%91%D7%99-%D7%9C%D7%97%D7%95%D7%A7-%D7%A9%D7%9B%D7%A8-%D7%A9%D7%95%D7%95%D7%94-%D7%9C%D7%A2%D7%95%D7%91%D7%93%D7%AA-%D7%95%D7%9C%D7%A2%D7%95%D7%91%D7%93.aspx",
            "originUrl":"",
            "reportPath":"",
            "missing":false,
            "note":"html"
        },
        {
            "name":"דן",
            "engName":"dan",
            "year":2021,
            "reportUrl":"https://assets.website-files.com/6187fd75db2d26d82458161a/629de712a9d11346e09c1a1f_%2006-06-22%20%D7%97%D7%95%D7%A7%20%D7%A9%D7%9B%D7%A8%20%D7%A9%D7%95%D7%95%D7%94%20-%20%D7%93%D7%9F.pdf",
            "originUrl":"",
            "reportPath":"דן_2021.pdf",
            "missing":false,
            "note":""
        },
        {
            "name":"דן בדרום",
            "engName":"dan darom",
            "year":2022,
            "reportUrl":"https://www.danbadarom.co.il/%D7%90%D7%95%D7%93%D7%95%D7%AA/",
            "originUrl":"",
            "reportPath":"דן_בדרום_2022.pdf",
            "missing":false,
            "note":""
        }
    ]
};
